import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom"; // Importa BrowserRouter
import App from "./approuter";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-image-lightbox/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

require("./client/assets/icons/fontawesome/css/fontawesome.min.css");
require("./client/assets/icons/fontawesome/css/all.min.css");
require("./client/assets/icons/feather/css/iconfont.css");
require("./client/assets/scss/main.scss");
require("../src/client/components/customstyleclient.css");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);