/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../assets/images/logo.png";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const Footer = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const exclusionArray = [
    "/pages/doctor-grid",
    "/pages/doctor-list",
    "/pages/video-call",
    "/pages/voice-call",
    "/pages/chat-doctor",
    "/patient/doctor-list",
    "/patient/doctor-grid",
  ];
  if (exclusionArray.indexOf(props.location.pathname) >= 0) {
    return "";
  }

  return (
      <>
        <style>
          {`
                .footer, .footer-one, .footer-top{
                  background: #0FC2EA !important;
                  color: white !important;
                  z-index: 9999 !important;
                  position: relative;
                }
                
                .footer .footer-top .footer-widget .footer-logo{
                  margin: 0px 60px 30px 0px !important;
                }
                
                .footer-one .footer-top .footer-widget .footer-about-content p{
                  color: white !important;
                }
                
                .footer-one .footer-bottom .copyright{
                  background: #0FC2EA !important;
                  color: white !important;
                }
                
                .footer-one .footer-top .footer-widget{
                  color: white !important;
                }
                
                .copyright-text, .footer.footer-one .footer-bottom .copyright .copyright-text p{
                  color: white !important;
                }
                
                .footer-one .footer-top .footer-widget.footer-menu ul li a,
                .footer-one .footer-top .footer-widget .footer-contact-info .footer-address p,
                .footer-one .footer-bottom .copyright .policy-menu li,
                .footer-one .footer-bottom .copyright .policy-menu li a{
                  color: white !important;
                }
                
                .option-footer,
                .option-footer:hover{
                  color: white !important;
                }
                
                .color-title {
                  color: #1C244B !important;
                }
            `}
        </style>
        {!props.location.pathname.includes("/index-6") &&
            !props.location.pathname.includes("/index-7") &&
            !props.location.pathname.includes("/index-8") &&
            !props.location.pathname.includes("/index-6") &&
            !props.location.pathname.includes("/index-7") &&
            !props.location.pathname.includes("/index-8") && (
                <footer className="footer footer-one">
                  <div className="footer-top">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-3 col-md-3">
                          <div className="footer-widget footer-about">
                            <a href="/home">
                              <div className="footer-logo" >
                                <img src='/assets/img/logo-Sfondo-trasparente-Segreteria-medica-3SMB-1.png' alt="logo"/>
                              </div>
                              </a>
                            <div className="footer-about-content color-option-footer">
                              <p>
                                Segreteria Medica 3SMB, società specializzata in servizi di segreteria online per MMG, Professionisti sanitari e Studi medici.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div className="footer-widget footer-menu">
                            <h2 className="footer-title color-title">Informazioni</h2>
                            <ul>
                              <li>
                                <a href="https://segreteriamedica3smb.it/">
                                  Sito web
                                </a>
                              </li>
                              <li>
                                <a href="https://segreteria.3smb.it/#/accesso">Accedi</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="footer-widget footer-contact">
                            <h2 className="footer-title color-title">Contatti</h2>
                            <div className="footer-contact-info">
                              <div className="footer-address">
                                <p>
                                  <i>
                                    <FeatherIcon
                                        icon="map-pin"
                                        style={{width: "16px", height: "16px"}}
                                    />
                                  </i>{" "}
                                  Via Ilaria Alpi 21,
                                  Scarperia e S. Piero a Sieve,
                                  50038 (FI)
                                </p>
                              </div>
                              <div className="footer-address">
                                <p>
                                  <i>
                                    <FeatherIcon
                                        icon="phone-call"
                                        style={{width: "16px", height: "16px"}}
                                    />
                                  </i>{" "}
                                  <a className="option-footer" href="tel:+390550540921">+39 0550540921</a>
                                </p>
                              </div>
                              <div className="footer-address mb-0">
                                <p>
                                  <i>
                                    <FeatherIcon
                                        icon="mail"
                                        style={{width: "16px", height: "16px"}}
                                    />
                                  </i>{" "}
                                  <a className="option-footer" href="mailto:info@3smb.it">info@3smb.it</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2">
                          <div className="footer-widget">
                            <h2 className="footer-title color-title">Social</h2>
                            <div className="social-icon">
                              <ul>
                                <li>
                                  <a href="https://www.facebook.com/SegreteriaMedica/?locale=it_IT">
                                    <i className="fab fa-facebook"/>{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.instagram.com/segreteriamedica3smb/">
                                    <i className="fab fa-instagram"/>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.linkedin.com/company/3smb-segreteria-medica-online/?originalSubdomain=it">
                                    <i className="fab fa-linkedin-in"/>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-bottom">
                    <div className="container">
                      {/* Copyright */}
                      <div className="copyright">
                        <div className="row">
                          <div className="col-md-8 col-lg-8">
                            <div className="copyright-text">
                              <p className="mb-0">
                                {" "}
                                Copyright © 2024{" "}
                                All Rights Reserved - SEGRETERIA MEDICA 3SMB - PARTITA IVA: 07096580480
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-4">
                            {/* Copyright Menu */}
                            <div className="copyright-menu">
                              <ul className="policy-menu">
                                <li>
                                  <a className="mx-2" href="/cookie-policy">
                                    Cookie Policy
                                  </a>
                                  <a className="mx-2" href="https://segreteriamedica3smb.it/privacy-policy/?_gl=1*1olzgco*_ga*OTc3MjAyMjgwLjE3MjE4MDcxNTY.*_ga_P3GQZNMCJH*MTcyNDA1OTc1MS43LjEuMTcyNDA1OTc5Ni4wLjAuMA..*_gcl_au*MTU4OTM4OTEyMS4xNzIxODA3MTU2*_ga_FDC0CW9FQ3*MTcyNDA1OTc1MS43LjEuMTcyNDA1OTc5Ni4xNS4wLjA.">
                                    Privacy Policy
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/* /Copyright Menu */}
                          </div>
                        </div>
                      </div>
                      {/* /Copyright */}
                    </div>
                  </div>
                </footer>
            )}
      </>
  );
};

export default Footer;
