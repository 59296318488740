import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Dropdown } from "primereact/dropdown";
import config from "../../../../../config";

const Iframe = (props) => {
  const [selectValueSpecialization, setSelectValueSpecialization] = useState([]);
  const [selectValueProvince, setSelectValueProvince] = useState([]);
  const [selectedValueSpecialization, setSelectedValueSpecialization] = useState(null);
  const [selectedValueProvince, setSelectedValueProvince] = useState(null);
  const [filteredPractitioners, setFilteredPractitioners] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const clinicSlider = {
    loop: true,
    margin: 15,
    dots: false,
    nav: true,
    navContainer: ".slide-nav-1",
    navText: [
      '<i class="fas fa-chevron-left custom-arrow"></i>',
      '<i class="fas fa-chevron-right custom-arrow"></i>',
    ],
    responsive: {
      0: { items: 1 },
      500: { items: 1 },
      768: { items: 3 },
      1000: { items: 4 },
      1300: { items: 6 },
    },
  };

  const combinedOptions = [
    ...(Array.isArray(filteredPractitioners) ? filteredPractitioners.map(p => ({
      ...p,
      name: `👨‍⚕️ ${p.name + ', '+ p.specializationDescription}`
    })) : []),
    ...selectValueSpecialization
  ];

  const getSpecialization = () => {
    const url = `${config.apiUrl}/SMB/SpecializationList`;

    return fetch(url)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Response is not JSON");
          }
        })
        .then((responseData) => {
          const data = responseData.data;
          const formattedData = [];
          for (var i = 0; i < data.length; i++) {
            const item = data[i];
            if (item && item.description && item.description !== 'Amministrazione') {
              formattedData.push({ id: item.id, name: item.description });
            }
          }
          return formattedData;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          throw error;
        });
  };

  const getProvince = (italyOnly) => {
    const url = `${config.apiUrl}/SMB/ProvinceList?italyOnly=${italyOnly}`;

    return fetch(url)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Response is not JSON");
          }
        })
        .then((responseData) => {
          const data = responseData.data;
          const formattedData = [];
          for (var i = 0; i < data.length; i++) {
            const item = data[i];
            if (item && item.id && item.description && item.id !== "EW3YE" && item.description !== " Provincia o comune non presente") {
              formattedData.push({ id: item.id, name: item.description });
            }
          }
          return formattedData;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          throw error;
        });
  };

  const getPractitionerListByRequest = async (request) => {
    const url = `${config.apiUrl}/SMB/PractitionersListByRequest?request=${request}`;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Response is not JSON");
      }

      const responseData = await response.json();

      if (Array.isArray(responseData.data.content) && responseData.data.content.length) {
        const practitioners = responseData.data.content.map(item => {
          return {
            id: item.practitioner.id,
            name: `${item.practitioner.firstName} ${item.practitioner.lastName}`,
            specializationId: item.practitionerSpecializationId,
            specializationDescription: item.specialization.description,
            type: 'practitioner'
          };
        });
        return practitioners;
      } else {
        console.error("No valid practitioners found:", responseData);
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  useEffect(() => {

    const handleScroll = () => {
      AOS.refresh();
    };
    
    AOS.init({ duration: 1000 });

    getSpecialization()
        .then((formattedData) => {
          setSelectValueSpecialization(formattedData);
        })
        .catch((error) => {
          console.error('Errore durante il recupero dei dati:', error);
        });

    getProvince(true)
        .then((formattedData) => {
          setSelectValueProvince(formattedData);
        })
        .catch((error) => {
          console.error('Errore durante il recupero dei dati:', error);
        });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSpecializationChange = async (e) => {
    const query = e.value || (e.target ? e.target.value : "");
    if(!query.type){
      setInputValue(query);
    }

    if (query && query.length >= 2) {
      try {
        const practitioners = await getPractitionerListByRequest(query);
        setFilteredPractitioners(practitioners);
      } catch (error) {
        console.error("Errore durante il recupero degli specialisti:", error);
      }
    } else {
      setFilteredPractitioners([]);
    }

    if (query.type === 'practitioner') {
      const DocId = query.id;
      const SpeId = query.specializationId;

      const cleanString = query.name.trim().split(' ').slice(1).join(' ');
      const nome = cleanString.split(' ').slice(0, 1).join(' ');
      const cognome = cleanString.split(',')[0].split(' ').slice(1).join(' ');
      const specifica = cleanString.split(',')[1].trim();

      const matchingSpecialization = selectValueSpecialization.find(
          (specialization) => specialization.name === query.specializationDescription
      );

      const SC = matchingSpecialization ? matchingSpecialization.id : null;

      window.location.href = `/profilo-dottore/${encodeURIComponent((cognome || '').replace(/\s+/g, '-'))}-${encodeURIComponent((nome || '').replace(/\s+/g, '-'))}/${encodeURIComponent((specifica || '').replace(/\s+/g, '-'))}/${DocId || ''}/${SpeId || ''}/${SC || ''}`;
    }
    setSelectedValueSpecialization(query);
  };

  const handleProvinceChange = (e) => {
    setSelectedValueProvince(e.value);
  };

  return (
      <div className="main-wrapper home-three">
        {/* Home Banner */}
        <section className="doctor-search-section">
          <style>
            {`
              .input-box-twelve{
                padding: 15px 20px 15px 20px !important;
              }
              
              @media only screen and (min-width: 600px) {
                .doctor-search-section .doctor-search .input-box-twelve .input-block:first-child{
                  width: 50%;
                }
              }
              
              .doctor-search-section .doctor-search .input-box-twelve .search-btn-info a{
                  background-color: #1C244B !important;
              }
              
              .doctor-search-section .doctor-search .input-box-twelve .search-btn-info a:hover{
                background-color: #0FC2EA !important;
              }
              
              .doctor-search-section{
                padding-top: 20px !important;
              }
              
              .custom-prime-select-option{
                  border: none;
                  background: white;
                  color: lightgrey;
                  margin-top: 0px !important;
                  border-radius: 10px !important;
                  padding-right: 10px !important;
                  padding-left: 10px !important;
                  position: relative;
                  width: 95% !important;
              }
              
              .custom-prime-select-option.p-dropdown.p-component.p-inputwrapper{
                width: 95% !important:
              }
              
              .p-dropdown-panel{
                z-index: 9999 !important;
              }
            `}
          </style>
          <div className="container">
            <div className="row align-items-center pb-4">
              <div className="col-0 col-md-2"></div>
              <div className="col-12 col-md-8">
              <div className="doctor-form">
                <form action="#" className="doctor-search">
                  <div className="input-box-twelve">
                    <div className="search-input input-block">
                      <Dropdown
                          value={inputValue}
                          onChange={handleSpecializationChange}
                          onInput={(e) => handleSpecializationChange(e)}
                          options={combinedOptions}
                          optionLabel="name"
                          placeholder="Specializzazione e medico"
                          className="custom-prime-select-option"
                          filter
                          filterBy="name"
                      />

                      <Link
                          className="current-loc-icon current_location"
                          to="#"
                      >
                        <i className="fa-solid fa-user-check"/>
                      </Link>
                    </div>
                    <div className="search-input input-block">
                      <Dropdown
                          value={selectedValueProvince}
                          onChange={handleProvinceChange}
                          options={selectValueProvince}
                          optionLabel="name"
                          placeholder="Provincia"
                          className="custom-prime-select-option"
                          filter
                          filterBy="name"
                      />
                      <Link
                          className="current-loc-icon current_location"
                          to="#"
                      >
                        <i className="fa-solid fa-location-crosshairs"/>
                      </Link>
                    </div>
                    <div className="input-block">
                      <div className="search-btn-info">
                        <a href={`/lista-dottori/${(selectedValueSpecialization?.name ?? 'und').replace(/\s+/g, '-').replace(/\//g, '-')}/${(selectedValueProvince?.name ?? 'und').replace(/\s+/g, '-')}/${selectedValueProvince?.id ?? 'und'}/${selectedValueSpecialization?.id ?? 'und'}`}>
                          <i className="fa-solid fa-magnifying-glass"/>
                          Cerca
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              </div>
              <div className="col-0 col-md-2"></div>
            </div>
          </div>
        </section>
      </div>
  );
};
export default Iframe;
