import React, { useEffect, useState } from "react";
import Header from "../../home/home/header";
import Footer from "../../footer";
import Doctors from "./doctors";
import Iframe from '../../home/home/iframe-doctor';

const SearchDoctor2 = (props) => {

  const [minValue, setMinValue] = useState(10);
  const [maxValue, setMaxValue] = useState(5000);

  useEffect(() => {
    if (document.getElementById("price-range")) {
      setMinValue(10);
      setMaxValue(10000);

      const slider = document.getElementById("price-range");
      slider.addEventListener("input", handleSliderChange);

      return () => {
        slider.removeEventListener("input", handleSliderChange);
      };
    }
  }, []);

  const handleSliderChange = (event) => {
    const min = parseInt(event.target.value.split(",")[0]);
    const max = parseInt(event.target.value.split(",")[1]);

    setMinValue(min);
    setMaxValue(max);
  };
  return (
      <div className="main-wrapper">
          <Header {...props} />

          {/* Breadcrumb */}
          <div className="breadcrumb-bar-two">
              <div className="container">
                  <div className="row align-items-center inner-banner">
                      <div className="col-md-12 col-12 text-center">
                          <h2 className="breadcrumb-title">Seleziona il dottore</h2>
                      </div>
                  </div>
              </div>
          </div>

          <div className="row" style={{position: "relative", top: "-50px", zIndex: "999"}}>
              <div className="col-md-12">
                  <Iframe/>
              </div>
          </div>

          <div className="doctor-content content">
              <div className="container">
                  <Doctors/>
              </div>
          </div>
          <Footer {...props} /> 
      </div>
  );
};

export default SearchDoctor2;
