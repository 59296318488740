export const base_path = "/";
// let path =''

// if (process.env.NODE_ENV === 'production') {
//     path = '/';
// } else {
//     // Use a different path for local development
//     path = '/';
// }
// export const image_path = '/react/template/'  // for Production
export const image_path = '/'                // for Development