const config = {

    apiKeyMaps: "AIzaSyCRlSy2dytQ7woFQOql9YE-B9gq7g0lQws",

    //LOCAL
    //apiUrl: "https://localhost:44342",
    //codeOffice: "1GMXE",
    //specializationSlider: ['MDLYE', '', '8EKPD', '', '', ''],

    //TEST
    //url: "3smb.carcadott.it",
    //apiUrl: "https://test-back.cercadott.it",
    //codeOffice: "1GMXE",
    //specializationSlider: ['MDLYE', '', '8EKPD', '', '', ''],

    //PRODUCTION
    url: "3smb.carcadott.it",
    apiUrl: "https://back.cercadott.it",
    codeOffice: "DLD09",
    specializationSlider: ['P9XYZ', '9MW2Z', '2L7M9', '3L0WL', 'DLD09', 'L7VM9'],
 
};

export default config;