import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Header from "../../home/home/header";
import Footer from "../../footer";
import StickyBox from "react-sticky-box";
import config from "../../../../../config";
import { format, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"></link>

const Checkout = (props) => {
    
    const {Sl, Sle, D, Di, Si, U, Ty} = useParams();
    
    const [isLogin, setIsLogin] = useState(true);
    
    const [selectValueProvince, setSelectValueProvince] = useState([]);
    const [selectedValueProvince, setSelectedValueProvince] = useState(null);

    const [selectValueMunicipality, setSelectValueMunicipality] = useState([]);
    const [selectedValueMunicipality, setSelectedValueMunicipality] = useState(null);

    const [selectedValueProvinceResidence, setSelectedValueProvinceResidence] = useState(null);

    const [selectValueMunicipalityResidence, setSelectValueMunicipalityResidence] = useState([]);
    const [selectedValueMunicipalityResidence, setSelectedValueMunicipalityResidence] = useState(null);

    const [selectValueProfession, setSelectValueProfession] = useState([]);
    const [selectedValueProfession, setSelectedValueProfession] = useState(null);

    const [doctor, setDoctor] = useState(null);
    const [doctorSpecialization, setdoctorSpecialization] = useState(null);
    const [office, setOffice] = useState(null);
    
    const [doctorSpecializationId, setdoctorSpecializationId] = useState(null);
    
    const [slot, setSlot] = useState([]);
    const [slotEnd, setSlotEnd] = useState([]);
    const [appointmentType, setAppointmentType] = useState([]);
    const [date, setDate] = useState([]);
    const [day, setDay] = useState([]);

    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(true);

    const Loader = () => {
        return (
            <div className="loader"></div>
        );
    };
    
    const SimplePopup = () => {
        return (
            <>
                {show && (
                    <div className="modal show d-block" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    Conferma mail
                                </div>
                                <div className="modal-body">
                                    <p>Si prega di accedere alla propria mail e confermare la registrazione. Una volta confermata premere su continua per registrare la prenotazione</p>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="button" className="btn btn-primary" onClick={handleRegistrationClickFinal}>
                                        Continua con la prenotazione
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };
    
    const toggleForm = () => {
        setIsLogin(!isLogin);
        document.getElementById('error-message') ? document.getElementById('error-message').style.display = 'none' : null;
        document.getElementById('success-message') ? document.getElementById('success-message').style.display = 'none' : null;
        document.getElementById('error-message-reg') ? document.getElementById('error-message-reg').style.display = 'none' : null;
    };

    const getDay = (date) => {
        const dateTmp = new Date(date);
        const dayOfWeek = dateTmp.getDay(); // Restituisce il numero del giorno della settimana
        return dayOfWeek;
    };
    
    const handleLoginClick = async () => {
        setLoading(true);

        document.getElementById('error-message') ? document.getElementById('error-message').style.display = 'none' : null;
        document.getElementById('success-message') ? document.getElementById('success-message').style.display = 'none' : null;
        document.getElementById('error-message-reg') ? document.getElementById('error-message-reg').style.display = 'none' : null;
        
        const email = document.querySelector('input[name="email"]').value;
        const password = document.querySelector('input[name="password"]').value;

        const editBooking = document.getElementById('edit-booking');
        
        try {
            const successMessage = document.getElementById('success-message');
            
            const dayOfWeek = getDay(date);
            
            const result = await createAppointment(email, password, dayOfWeek);
            
            document.getElementById('login-form').style.display = 'none';
            
            if (successMessage) {
                successMessage.textContent = 'Prenotazione avvenuta con successo!';
                successMessage.style.display = 'block';
                editBooking.style.display = 'none';
            }
            setLoading(false);
        } catch (error) {
            const errorMessage = document.getElementById('error-message');
            setLoading(false);
            if (errorMessage) {
                errorMessage.textContent = 'Errore durante la prenotazione. Per favore controlla che la mail e la password siano corrette e riprova.';
                errorMessage.style.display = 'block';
            }
        }
    };

    const handleFormSubmit = (event) => {
        if (!event.currentTarget.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        event.preventDefault();
        handleRegistrationClickInitial();
    };

    
    const handleRegistrationClickInitial = async () => {
        try {
            setLoading(true);

            document.getElementById('error-message') ? document.getElementById('error-message').style.display = 'none' : null;
            document.getElementById('success-message') ? document.getElementById('success-message').style.display = 'none' : null;
            document.getElementById('error-message-reg') ? document.getElementById('error-message-reg').style.display = 'none' : null;
            
            const response = await submitPatientForm();
    
            if(response.data != null){
                setShow(true);
                setLoading(false);
            }else{
                const errorMessage = document.getElementById('error-message-reg');
        
                if (errorMessage) {
                    let message = response.errors[0].message;

                    if (message.includes('Username already exists')) {
                        message = 'Il nome utente o la mail esistono già';
                    }
                    errorMessage.textContent = message || 'Errore durante la prenotazione. Per favore riprova.';
                    errorMessage.style.display = 'block';
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            const errorMessage = document.getElementById('error-message-reg');
            
            if (errorMessage) {
                errorMessage.textContent = 'Errore durante la prenotazione. Per favore riprova.';
                errorMessage.style.display = 'block';
            }
        }
    };
    

    const handleRegistrationClickFinal = async () => {
        setLoading(true);
        const email = document.querySelector('input[name="email"]').value;
        const password = document.querySelector('input[name="password"]').value;

        const editBooking = document.getElementById('edit-booking');
        
        try {
            setShow(false);

            const successMessage = document.getElementById('success-message');
            const dayOfWeek = getDay(date);
            const result = await createAppointment(email, password, dayOfWeek);
            document.getElementById('registration-form').style.display = 'none';

            if (successMessage) {
                successMessage.textContent = 'Prenotazione avvenuta con successo!';
                successMessage.style.display = 'block';
                editBooking.style.display = 'none';
            }
            setLoading(false);
        } catch (error) {
            const errorMessage = document.getElementById('error-message-reg');
            setLoading(false);
            if (errorMessage) {
                errorMessage.textContent = 'Errore durante la prenotazione. Per favore riprova.';
                errorMessage.style.display = 'block';
            }
        }
    };


    const createAppointment = async (email, password, dayOfWeek) => {
        const appointmentDetailDto = {
            scheduleAppointmentTypeId: appointmentType.scheduleAppointmentTypeId,
            appointmentData: {
                office: {
                    id: office.id,
                    description: office.name,
                    code: null
                },
                appointmentDate: date,
                startTime: slot,
                endTime: slotEnd,
                creationTime: null,
                creationDate: null
            },
            dayOfWeek: dayOfWeek,
            notes: null
        };

        try {
            const response = await fetch(`${config.apiUrl}/SMB/CreateAppointment?email=${email}&password=${password}&practitionerSpecilizationId=${doctorSpecializationId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(appointmentDetailDto),
            });

            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                const result = await response.json();

                if (!response.ok || result.data == null) {
                    throw new Error(result.error?.message || 'Errore nella creazione dell\'appuntamento');
                }
                return result;
            } else {
                const text = await response.text();
                throw new Error(`Response is not JSON: ${text}`);
            }
        } catch (error) {
            console.error('Errore durante la chiamata API per creare l\'appuntamento:', error);
            throw error;
        }
    };

    const submitPatientForm = async () => {
        const firstName = document.querySelector('input[name="firstName"]').value;
        const lastName = document.querySelector('input[name="lastName"]').value;
        const email = document.querySelector('input[name="email"]').value;
        const fiscalCode = document.querySelector('input[name="fiscalCode"]').value;
        const phoneNumber = document.querySelector('input[name="phoneNumber"]').value;
        const birthPlace = selectedValueMunicipality;
        const birthProvince = selectedValueProvince;
        const birthDate = document.querySelector('input[name="birthDate"]').value;
        const gender = document.querySelector('select[name="gender"]').value;
        const address = document.querySelector('input[name="address"]').value;
        const postalCode = document.querySelector('input[name="postalCode"]').value;
        const jobId = selectedValueProfession;
        const addressMunicipalityId = selectedValueMunicipalityResidence;
        const mobileNumber = document.querySelector('input[name="phoneNumber"]').value;
        const practitionerSpecializationsIds = [doctorSpecializationId];
        
        const insertUserDTO = {
            username: document.querySelector('input[name="email"]').value,
            password: document.querySelector('input[name="password"]').value,
            confirmPassword: document.querySelector('input[name="confirmPassword"]').value,
            termAcceptance: document.querySelector('input[name="termAcceptance"]').checked
        };
        
        const patientDto = {
            firstName,
            lastName,
            email,
            fiscalCode,
            phoneNumber,
            mobileNumber,
            birthPlace,
            birthProvince,
            birthDate,
            gender,
            address,
            postalCode,
            jobId,
            addressMunicipalityId,
            insertUserDTO,
            practitionerSpecializationsIds : practitionerSpecializationsIds
        };

        try {
            const response = await fetch(`${config.apiUrl}/SMB/CreatePatient`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(patientDto)
            });
        
            const jsonResponse = await response.json();
        
            if (response.ok) {
                return jsonResponse;
            } else {
                const errorMessage = jsonResponse.errors && jsonResponse.errors.length > 0 
                    ? jsonResponse.errors[0].message 
                    : 'Si è verificato un errore';
                throw new Error(errorMessage);
            }
        } catch (error) {
            throw new Error(error.message);
        }
        
        
    };


    useEffect(() => {

        const slot = Sl;
        const slotEnd = Sle;
        const date = D;
        if(slot == null || slotEnd == null || date == null){
            window.location.href = 'http://localhost:3000/home';
        }
        const appointmentType = Ty;
        const parsedDate = parseISO(date);
        const formattedDate = format(parsedDate, "eeee d MMMM yyyy", { locale: it });
        setSlot(slot);
        setSlotEnd(slotEnd);
        setDate(date);
        setDay(formattedDate);
        const doctorId = Di;
        const doctorSpecializationId = Si;

        setdoctorSpecializationId(doctorSpecializationId);
        
        const officeId = U;

        getProvince()
            .then((formattedData) => {
                setSelectValueProvince(formattedData);
            })
            .catch((error) => {
                console.error('Errore durante il recupero dei dati:', error);
            });
        

        JobList()
            .then((formattedData) => {
                setSelectValueProfession(formattedData);
            })
            .catch((error) => {
                console.error('Errore durante il recupero dei dati:', error);
            });

        getPractitionerInfo(doctorId)
            .then((formattedData) => {
                setDoctor(formattedData);
            })
            .catch((error) => {
                console.error('Errore durante il recupero dei dati:', error);
            });
        
        getOfficeInfo(doctorSpecializationId, officeId)
            .then((formattedData) => {
                setOffice(formattedData.data);
            })
            .catch((error) => {
                console.error('Errore durante il recupero dei dati:', error);
            });

        getPractitionerSpecialization(doctorId)
            .then((formattedData) => {
                setdoctorSpecialization(formattedData.data.find(item => item.practitionerSpecializationId === doctorSpecializationId).specialization);
            })
            .catch((error) => {
                console.error('Errore durante il recupero dei dati:', error);
            });

        getPractitionerAppointmentTypesList(doctorSpecializationId)
            .then((formattedData) => {
                const filteredData = formattedData.data.filter(item => item.scheduleAppointmentTypeId === appointmentType);
                setAppointmentType(filteredData[0]);
            })
            .catch((error) => {
                console.error('Errore durante il recupero dei dati:', error);
            });
        
        setLoading(false);
        
    }, []);

    const handleProvinceChange = (e) => {
        const selectedId = e.target.value;
        setSelectedValueProvince(selectedId);
    
        getMunicipality(selectedId)
            .then((formattedData) => {
                setSelectValueMunicipality(formattedData);
            })
            .catch((error) => {
                console.error('Errore durante il recupero dei dati:', error);
            });
    };

    const handleMuncipalityChange = (e) => {
        setSelectedValueMunicipality(e.target.value);
    };

    const handleProvinceChangeResidence = (e) => {
        const selectedId = e.target.value;
        setSelectedValueProvinceResidence(selectedId);
        
        getMunicipality(selectedId)
            .then((formattedData) => {
                setSelectValueMunicipalityResidence(formattedData);
            })
            .catch((error) => {
                console.error('Errore durante il recupero dei dati:', error);
            });
    };
    
    const handleMuncipalityChangeResidence = (e) => {
        setSelectedValueMunicipalityResidence(e.target.value);
    };
    
    const handleChangeProfession = (e) => {
        setSelectedValueProfession(e.target.value);
    };

    const getProvince = (italyOnly = true) => {
        const url = `${config.apiUrl}/SMB/ProvinceList?italyOnly=${italyOnly}`;

        return fetch(url)
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                } else {
                    throw new Error("Response is not JSON");
                }
            })
            .then((responseData) => {
                const data = responseData.data;
                const formattedData = data
                    .filter(item => item && item.id && item.description && item.id !== "EW3YE")
                    .map(item => ({ id: item.id, name: item.description }));

                return formattedData;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                throw error;
            });
    };
    
    const getMunicipality = (provinceId) => {
        const url = `${config.apiUrl}/SMB/MunicipalityList?provinceId=${provinceId}`;

        return fetch(url)
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                } else {
                    throw new Error("Response is not JSON");
                }
            })
            .then((responseData) => {
                const data = responseData.data;
                const formattedData = data
                    .filter(item => item && item.id && item.description && item.id !== "EW3YE")
                    .map(item => ({ id: item.id, name: item.description, code: item.code, cap: item.defaultPostalCode }));

                return formattedData;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                throw error;
            });
    };

    const JobList = () => {
        const url = `${config.apiUrl}/SMB/JobList?`;
        return fetch(url)
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                } else {
                    throw new Error("Response is not JSON");
                }
            })
            .then((responseData) => {
                return responseData.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                throw error;
            });
    };

    const getPractitionerInfo = (doctorId) => {
        const url = `${config.apiUrl}/SMB/PractitionerInfo?PractitionerId=${doctorId}`;
        return fetch(url)
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                } else {
                    throw new Error("Response is not JSON");
                }
            })
            .then((responseData) => {
                return responseData.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                throw error;
            });
    };
    
    const getOfficeInfo = async (practitionerSpecializationId, officeId) => {
        try {
            const url = `${config.apiUrl}/SMB/OfficeInfo?PractitionerSpecializationId=${practitionerSpecializationId}&OfficeId=${officeId}`;
            const response = await fetch(url)
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                const data = await response.json();
                return data;
            } else {
                throw new Error("Response is not JSON");
            }
        } catch (error) {
            console.error('Error fetching office info:', error);
            throw error;
        }
    };
    
    const getPractitionerSpecialization = async (DoctorId) => {
        try {
            const url = `${config.apiUrl}/SMB/PractitionerSpecialization?PractitionerId=${DoctorId}`;
            const response = await fetch(url);
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                const data = await response.json();
                return data;
            } else {
                throw new Error("Response is not JSON");
            }
        } catch (error) {
            console.error('Error fetching office info:', error);
            throw error;
        }
    };

    const getPractitionerAppointmentTypesList = async (practitionerSpecializationId) => {
        try {
            const url = `${config.apiUrl}/SMB/PractitionerAppointmentTypesList?PractitionerSpecializationId=${practitionerSpecializationId}`;
            const response = await fetch(url);
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                const data = await response.json();
                return data;
            } else {
                throw new Error("Response is not JSON");
            }
        } catch (error) {
            console.error('Error fetching office info:', error);
            throw error;
        }
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const [showPassword1, setShowPassword1] = useState(false);

    const togglePassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const [showPassword2, setShowPassword2] = useState(false);

    const togglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    return (
        <div>
            <Header {...props} />

            {/* <!-- Breadcrumb --> */}
            <div className="breadcrumb-bar-two">
                <div className="container">
                    <div className="row align-items-center inner-banner">
                        <div className="col-md-12 col-12 text-center">
                            <h2 className="breadcrumb-title">Procedi con la prenotazione</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">

                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="App">
                <SimplePopup/>
            </div>

            <style>
                {`
                .theiaStickySidebar {
                    top: 100px !important;
                }

                .feather-eye {
                    content: url('https://www.svgrepo.com/show/13611/eye.svg'); /* Icona occhio */
                    cursor: pointer;
                    }

                .feather-eye-off {
                    content: url('https://www.svgrepo.com/show/13619/eye-crossed.svg'); /* Icona occhio barrato */
                    cursor: pointer;
                }
                
                .btn-option,
                .btn-option:hover{
                  color: #fff;
                  background-color: #1C244B !important;
                  border-color: #1C244B !important;
                }
            `}
            </style>

            {/* <!-- /Breadcrumb -->     */}

            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    {/* Checkout Form */}
                                    {!isLogin ? (
                                        <form id="registration-form" onSubmit={handleFormSubmit}>
                                            {/* Personal Information */}
                                            <div className="info-widget">
                                                <h4 className="card-title">Registrazione</h4>
                                                <div className="exist-customer">
                                                    Hai già un account?{" "}
                                                    <button type="button" onClick={toggleForm} className="btn btn-link">
                                                        Accedi
                                                    </button>
                                                </div>
                                                <div className="row mt-5">
                                                    {/* Nome */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Nome *</label>
                                                            <input name="firstName" className="form-control" type="text"
                                                                   required/>
                                                        </div>
                                                    </div>

                                                    {/* Cognome */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Cognome *</label>
                                                            <input name="lastName" className="form-control" type="text"
                                                                   required/>
                                                        </div>
                                                    </div>

                                                    {/* Email */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Email *</label>
                                                            <input name="email" className="form-control" type="email"
                                                                   required/>
                                                        </div>
                                                    </div>

                                                    {/* Telefono */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Telefono *</label>
                                                            <input name="phoneNumber" className="form-control"
                                                                   type="number" required/>
                                                        </div>
                                                    </div>

                                                    {/* Sesso */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Sesso *</label>
                                                            <select name="gender" className="form-control" required>
                                                                <option value="">Seleziona</option>
                                                                <option value="MALE">Maschio</option>
                                                                <option value="FEMALE">Femmina</option>
                                                                <option value="OTHER">Altro</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {/* Data di nascita */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Data di nascita *</label>
                                                            <input name="birthDate" className="form-control" type="date"
                                                                   required/>
                                                        </div>
                                                    </div>

                                                    {/* Provincia di nascita */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Provincia di nascita</label>
                                                            <select
                                                                name="provinceOfBirth"
                                                                className="form-control"
                                                                value={selectedValueProvince}
                                                                onChange={handleProvinceChange}
                                                                required
                                                            >
                                                                <option value="">Seleziona provincia</option>
                                                                {selectValueProvince && selectValueProvince.length > 0 ? (
                                                                    selectValueProvince.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option value="">Nessuna provincia disponibile</option>
                                                                )}
                                                            </select>                                         
                                                        </div>
                                                    </div>

                                                    {/* Comune di nascita */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Comune di nascita *</label>
                                                            <select
                                                                name="municipalityOfBirth"
                                                                className="form-control"
                                                                value={selectedValueMunicipality}
                                                                onChange={handleMuncipalityChange}
                                                                required
                                                            >
                                                                <option value="">Seleziona comune</option>
                                                                {selectValueMunicipality && selectValueMunicipality.length > 0 ? (
                                                                    selectValueMunicipality.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option value="">Nessun comune disponibile</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {/* Codice Fiscale */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Codice Fiscale *</label>
                                                            <input name="fiscalCode" className="form-control"
                                                                   type="text" required/>
                                                        </div>
                                                    </div>

                                                    {/* Professione */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Professione *</label>
                                                            <select
                                                                name="profession"
                                                                className="form-control"
                                                                value={selectedValueProfession}
                                                                onChange={handleChangeProfession}
                                                                required
                                                            >
                                                                <option value="">Seleziona professione</option>
                                                                {selectValueProfession && selectValueProfession.length > 0 ? (
                                                                    selectValueProfession.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                             {option.description}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option value="">Nessuna professione disponibile</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {/* Provincia di domicilio */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Provincia di domicilio *</label>
                                                            <select
                                                                name="provinceOfResidence"
                                                                className="form-control"
                                                                value={selectedValueProvinceResidence}
                                                                onChange={handleProvinceChangeResidence}
                                                                required
                                                            >
                                                                <option value="">Seleziona provincia</option>
                                                                {selectValueProvince && selectValueProvince.length > 0 ? (
                                                                    selectValueProvince.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option value="">Nessuna provincia disponibile</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {/* Comune di domicilio */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Comune di domicilio *</label>
                                                            <select
                                                                name="municipalityOfResidence"
                                                                className="form-control"
                                                                value={selectedValueMunicipalityResidence}
                                                                onChange={handleMuncipalityChangeResidence}
                                                                required
                                                            >
                                                                <option value="">Seleziona comune di domicilio</option>
                                                                {selectValueMunicipalityResidence && selectValueMunicipalityResidence.length > 0 ? (
                                                                    selectValueMunicipalityResidence.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option value="">Nessun comune disponibile</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {/* Indirizzo di domicilio */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Indirizzo di domicilio *</label>
                                                            <input name="address" className="form-control" type="text"
                                                                   required/>
                                                        </div>
                                                    </div>

                                                    {/* CAP */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>CAP *</label>
                                                            <input name="postalCode" className="form-control"
                                                                   type="text" required/>
                                                        </div>
                                                    </div>

                                                    {/* Password */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Password *</label>
                                                            <div className="pass-group">
                                                                <input name="password" type={showPassword1 ? "text" : "password"} className="form-control pass-input-sub" required/>
                                                                <span onClick={togglePassword1} className={showPassword1 ? "fa-regular fa-eye toggle-password" : "fa-regular fa-eye-slash toggle-password"}></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Conferma Password */}
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Conferma Password *</label>
                                                            <div className="pass-group">
                                                                <input name="confirmPassword" type={showPassword2 ? "text" : "password"} className="form-control pass-input-sub" required/>
                                                                <span onClick={togglePassword2} className={showPassword2 ? "fa-regular fa-eye toggle-password" : "fa-regular fa-eye-slash toggle-password"}></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <p style={{fontSize: '14px'}}>La password deve contenere lettere, includendo almeno una maiuscola e una minuscola, numeri e almeno un carattere speciale.</p>
                                                    </div>

                                                    {/* Termini e Condizioni */}
                                                    <div className="form-check d-flex align-items-center ms-4 mt-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="termAcceptance"
                                                            name="termAcceptance"
                                                            style={{width: '16px', height: '16px', marginRight: '8px'}}
                                                            required
                                                        />
                                                        <label className="form-check-label" htmlFor="termAcceptance">
                                                            Accetta i <a
                                                            href="https://segreteriamedica3smb.it/termini-e-condizioni-di-utilizzo/"
                                                            target="_blank"
                                                        >
                                                            termini
                                                        </a> e le&nbsp;
                                                            <a
                                                                href="https://segreteriamedica3smb.it/privacy-policy/"
                                                                target="_blank">
                                                                policy
                                                            </a>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div id="error-message-reg" className="alert alert-danger mt-3" style={{display: 'none'}} role="alert">
                                                </div>
                                            </div>
                                            {/* /Personal Information */}
                                            <div className="text-end mt-4">
                                                <button type="submit" className="btn btn-option">
                                                    Effettua prenotazione
                                                </button>
                                            </div>
                                        </form>
                                    ) : (
                                        <form id="login-form">
                                            <div className="info-widget">
                                                <h4 className="card-title">Accedi</h4>
                                                <div className="exist-customer">
                                                    Non hai un account?
                                                    <button type="button" onClick={toggleForm} className="btn btn-link">
                                                        Registrati qui
                                                    </button>
                                                </div>
                                                <div className="row mt-5">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Email</label>
                                                            <input className="form-control" type="email" name="email"
                                                                   required/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="form-group card-label">
                                                            <label>Password</label>
                                                            <div className="pass-group">
                                                                <input name="password" type={showPassword ? "text" : "password"} className="form-control pass-input-sub" required/>
                                                                <span onClick={togglePassword} className={showPassword ? "fa-regular fa-eye toggle-password" : "fa-regular fa-eye-slash toggle-password"}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="error-message" className="alert alert-danger mt-3"
                                                    style={{display: 'none'}} role="alert">
                                                </div>
                                            </div>
                                            <div className="text-end mt-4">
                                                <button type="button" onClick={handleLoginClick}
                                                        className="btn btn-option">
                                                    Effettua prenotazione
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                    <div>
                                        <div id="success-message" className="alert alert-success mt-3"
                                             style={{display: 'none'}} role="alert">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4 theiaStickySidebar">
                            <StickyBox offsetTop={100} offsetBottom={20}>
                                {/* Booking Summary */}
                                <div className="card booking-card">
                                    <div className="card-header">
                                        <h4 className="card-title">Informazioni prenotazione</h4>
                                    </div>
                                    <div className="card-body">
                                        {/* Booking Doctor Info */}
                                        <div className="booking-doc-info">
                                            <Link className="booking-doc-img">
                                                {doctor && doctor.practitionerInfo && doctor.practitionerInfo.practitionerImages && doctor.practitionerInfo.practitionerImages.length > 0 && doctor.practitionerInfo.practitionerImages[0].url !== null ? (
                                                    <img src={doctor.practitionerInfo.practitionerImages[0].url} alt=""/>
                                                ) : (
                                                    <img
                                                        src="/assets/img/doctor-placeholder.png" alt=""
                                                    />
                                                )}
                                            </Link>
                                            <div className="booking-info">
                                                {doctor && doctor.lastName && (
                                                    <h4>
                                                        Dr. {doctor.lastName} {doctor.firstName}
                                                    </h4>
                                                )}
                                            </div>
                                        </div>
                                        {/* Booking Doctor Info */}
                                        <div className="booking-summary">
                                            <div className="booking-item-wrap">
                                                <ul className="booking-date mt-4 pb-0">
                                                    <li>
                                                        {office && office.name && (
                                                            <h6>
                                                                {office.name}, {office.address}
                                                            </h6>
                                                        )}
                                                    </li>
                                                </ul>
                                                <ul className="booking-fee">
                                                    {doctorSpecialization && doctorSpecialization.description && (
                                                        <li>
                                                            Specializzazione: <span>{doctorSpecialization.description}</span>
                                                        </li>
                                                    )}
                                                </ul>
                                                <ul className="booking-fee">
                                                    {appointmentType && (
                                                        <li>
                                                            Tipologia prenotazione: <span>{appointmentType.description}</span>
                                                        </li>
                                                    )}
                                                </ul>
                                                <div className="booking-total">
                                                    <ul className="booking-total-list">
                                                        <li>
                                                        <span>Giorno:</span>
                                                            <span className="total-cost">{day}</span>
                                                        </li>
                                                        <li>
                                                            <span>Orario:</span>
                                                            <span className="total-cost">{slot}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {doctor && doctor.practitionerId && doctorSpecializationId && doctorSpecialization && doctorSpecialization.id && (
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <a type="button" className="btn btn-option" id="edit-booking" href={`/profilo-dottore/${encodeURIComponent((doctor?.lastName || '').replace(/\s+/g, '-'))}-${encodeURIComponent((doctor?.firstName || '').replace(/\s+/g, '-'))}/${encodeURIComponent((doctorSpecialization?.description || '').replace(/\s+/g, '-'))}/${doctor?.practitionerId || ''}/${doctorSpecializationId || ''}/${doctorSpecialization?.id || ''}`}>
                                                            Modifica prenotazione
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <style>
                                    {`    
                                            .loader {
                                              width: 150px;
                                              position: fixed;
                                              top: 50%;
                                              left: 48%;
                                              transform: translate(-50%, -50%); /* Per centrare l'immagine */
                                              animation: pulse 1s infinite; /* Animazione di pulsazione */
                                              z-index: 99999;
                                            }

                                            @media only screen and (max-width: 600px) {
                                                .loader {
                                                    left: 38%;
                                                }
                                            }

                                            @keyframes pulse {
                                            0% {
                                                transform: scale(1); /* Dimensione iniziale */
                                            }
                                            50% {
                                                transform: scale(1.1); /* Ingrandimento */
                                            }
                                            100% {
                                                transform: scale(1); /* Torna alla dimensione originale */
                                            }
                                        }

                                         .overlay {
                                            position: fixed; 
                                            left: 0; 
                                            width: 100%;
                                            height: 110%;
                                            top: -10%;
                                            background-color: rgba(0, 0, 0, 0.5); /* Colore semi-trasparente */
                                            z-index: 9999; /* Assicurati che l'overlay sia sotto il loader ma sopra il contenuto */
                                        }
                                        
                                    `}
                                </style>

                                {/* /Booking Summary */}
                            </StickyBox>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {loading ? <img src="/assets/img/logo-Sfondo-trasparente-Segreteria-medica-3SMB-1.png" alt="Logo" className="loader" /> : ''}
                {loading && <div className="overlay"></div>}
            </div>
            <Footer {...props} />
        </div>
    );
};

export default Checkout;
