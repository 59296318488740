import React, { useState, useEffect, useRef } from 'react';
import Header from "../../home/home/header";
import ImageWithBasePath from '../../../../core/img/imagewithbasebath';
import Footer from "../../footer";
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import config from "../../../../../config";
import {Dropdown} from "primereact/dropdown";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useParams } from 'react-router-dom';

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"/>

const DoctorProfileTwo = (props) => {

    const {docId, speId, sc } = useParams();
    
    const [doctor, setDoctor] = useState([]);
    const [office, setOffice] = useState([]);
    const [availability, setAvailability] = useState([]);
    const [info, setInfo] = useState([]);
    const [practitionerInfo, setPractitionerInfo] = useState([]);
    const [specializationId, setSpecialization] = useState([]);
    const [specialityId, setSpeciality] = useState([]);
    const [specializationList, setSpecializationList] = useState([]);

    const [selectValueSpecialization, setSelectValueSpecialization] = useState([]);
    const [selectValueOffice, setSelectValueOffice] = useState([]);
    const [selectedValueSpecialization, setSelectedValueSpecialization] = useState(null);
    const [selectedValueOffice, setSelectedValueOffice] = useState(null);
    const [selectedValueTypeAppointment, setSelectedValueTypeAppointment] = useState(null);
    const [selectValueTypeAppointment, setSelectValueTypeAppointment] = useState([]);

    const [loading, setLoading] = useState(true);

    const [initialLoad, setInitialLoad] = useState(true);

    const [isVisible, setIsVisible] = useState(false);

    const [alerts, setAlerts] = useState([]);
    const [popupData, setPopupData] = useState(null);

    const [todayCount, setTodayCount] = useState(new Date(Date.now() + 86400000)); // Aggiunge 1 giorno a oggi

    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpandedM, setIsExpandedM] = useState(false);


    const listRef = useRef(null);
    const listRefM = useRef(null);

    const updateDoctor = (newDoctor) => {
        if (doctor.length === 0) {
            setDoctor(newDoctor);
        }
    };
    
    useEffect(() => {

        if (practitionerInfo && doctor && selectValueSpecialization) {
            const specializationList = selectValueSpecialization
                .filter(item => item.specialization)
                .map(item => item.specialization.description)
                .join(', ');
 
            const descriptionContent =
                (specializationList.length > 0
                    ? `Specializzato in: ${specializationList}. `
                    : '') +
                (practitionerInfo && practitionerInfo.bioDescription
                    ? practitionerInfo.bioDescription
                    : 'Cerchi un dottore? Prenota un appuntamento con centinaia di specialisti.');

            const descriptionMetaTag = document.querySelector('meta[name="description"]');
            if (descriptionMetaTag) {
                descriptionMetaTag.setAttribute('content', descriptionContent);
            }

            const titleContent = `3SMB - ${doctor ? `Dr. ${doctor.lastName} ${doctor.firstName}` : 'Caricamento...'}`;

            document.title = titleContent;

            const titleMetaTag = document.querySelector('meta[name="title"]');
            if (titleMetaTag) {
                titleMetaTag.setAttribute('content', titleContent);
            }

        }

    }, [practitionerInfo, doctor, selectValueSpecialization]);

    const Loader = () => {
        return (
            <div className="loader"></div>
        );
    };

    const AlertPopup = ({ title, body, backgroundColor, textColor, onClose }) => {
        const handleOverlayClick = (e) => {
            if (e.target.classList.contains('modal') || e.target.classList.contains('modal-backdrop')) {
                onClose();
            }
        };

        return (
            <div
                className="modal fade show"
                tabIndex="-1"
                role="dialog"
                style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} // Overlay scuro
                onClick={handleOverlayClick}
            >
                <div
                    className="modal-dialog"
                    role="document"
                    style={{ marginTop: '15vh' }} // Spazio sopra il popup
                >
                    <div className="modal-content" style={{ backgroundColor, color: textColor }}>
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            <h5 className="modal-title">{title}</h5>
                            <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={onClose}
                                style={{ border: 'none', background: 'transparent', fontSize: '1.5rem', color: textColor }}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{body}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const handleClosePopup = () => {
        setPopupData(null);
    };
    
    function toggleContent() {
        var contentDiv;
        if(window.innerWidth < 768) {
            contentDiv = document.getElementById('clearfixM');
            if (isExpandedM) {
                contentDiv.style.maxHeight = '260px';
                setIsExpandedM(false);
            } else{
                contentDiv.style.maxHeight = 'none';
                setIsExpandedM(true);
            }
        }else{
            contentDiv = document.getElementById('clearfix');
            if (isExpanded) {
                contentDiv.style.maxHeight = '260px';
                setIsExpanded(false);
            } else{
                contentDiv.style.maxHeight = 'none';
                setIsExpanded(true);
            }
        }
    }

    const goToNextWeek = () => {
        const nextWeek = new Date(todayCount);
        if(window.innerWidth < 768){
            nextWeek.setDate(todayCount.getDate() + 3);
        }else{
            nextWeek.setDate(todayCount.getDate() + 6);
        }
        setTodayCount(nextWeek);
    };

    const goToPreviousWeek = () => {
        const previousWeek = new Date(todayCount);
        if(window.innerWidth < 768){
            previousWeek.setDate(todayCount.getDate() - 3);
        }else{
            previousWeek.setDate(todayCount.getDate() - 6);
        }
        const today = new Date();
        if (previousWeek > today) {
            setTodayCount(previousWeek);
        }
    };

    const handleSpecializationChange = async (e) => {
        await setSelectedValueSpecialization(e.value);
        updatePage(e.value);
    };

    const handleOfficeChange = async (e) => {
        await setSelectedValueOffice(e.value);
    };

    const handleTypeAppointmentChange = async (e) => {
        await setSelectedValueTypeAppointment(e.value);
    };

    function updateDate() {
        if (selectedValueSpecialization && selectedValueOffice && selectedValueTypeAppointment) {
            getPractitionerApointmentsByInterval(specializationId, selectedValueOffice.id)
                .then((formattedData) => {
                    setAvailability(formattedData.data);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Errore durante il recupero dei dati:', error);
                });
            setIsVisible(true);
        }
    }

    function Alert() {
        getAlert("BANNER", specializationId, selectedValueTypeAppointment.scheduleAppointmentTypeId)
            .then((data) => {
                setAlerts(data.data);
            })
            .catch((error) => {
                console.error('Errore durante il recupero dei dati:', error);
            });

        getAlert("MODAL", specializationId, selectedValueTypeAppointment.scheduleAppointmentTypeId)
            .then((data) => {
                if (Array.isArray(data.data) && data.data.length > 0) {
                    setPopupData(data.data[0]);
                }
            })
            .catch((error) => {
                console.error('Errore durante il recupero dei dati:', error);
            });
    }

    function updatePage(SC) {
        const currentUrl = new URL(window.location.href);
        const segments = currentUrl.pathname.split("/");
        var SpeId = specializationList.find(item => item.specialization.id === SC).practitionerSpecializationId;
        segments[5] = SpeId;
        segments[6] = SC;
        currentUrl.pathname = segments.join("/");
        window.location.href = currentUrl.toString();
    }

    const timeToDate = (timeStr, referenceDate) => {
        const [hours, minutes, seconds] = timeStr.split(':').map(Number);
        const date = new Date(referenceDate);
        date.setHours(hours, minutes, seconds, 0);
        return date;
    };

    const formatTime24 = (date) => {
        if (!(date instanceof Date)) {
            throw new Error('Input is not a Date object');
        }
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const generateSubSlots = (startTimeStr, endTimeStr, referenceDate) => {
        const startTime = timeToDate(startTimeStr, referenceDate);
        const endTime = timeToDate(endTimeStr, referenceDate);
        const subSlots = [];
        let currentStartTime = startTime;
        const durationMillis = selectedValueTypeAppointment.duration * 60000;

        while (currentStartTime < endTime) {
            const currentEndTime = new Date(currentStartTime.getTime() + durationMillis);
            if (currentEndTime > endTime) {
                break;
            }
            subSlots.push({
                start: formatTime24(currentStartTime),
                end: formatTime24(currentEndTime)
            });
            currentStartTime = currentEndTime;
        }

        return subSlots;
    };

    const ourlog = {
        loop: true,
        margin: 24,
        dots: false,
        nav: true,
        smartSpeed: 2000,
        navContainer: '.slide-1',
        navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            500: {
                items: 1
            },
            768: {
                items: 2
            },
            1200: {
                items: 6
            },
            1300: {
                items: 6
            }
        }
    };

    const ouraward = {
        loop: true,
        margin: 24,
        dots: false,
        nav: true,
        smartSpeed: 2000,
        navContainer: '.slide-3',
        navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            500: {
                items: 1
            },
            768: {
                items: 2
            },
            1200: {
                items: 4
            },
            1300: {
                items: 4
            }
        }
    };

    useEffect(() => {
        if (selectedValueSpecialization && selectedValueOffice && selectedValueTypeAppointment) {
            setLoading(true);
            updateDate();
        }else if (!selectedValueSpecialization && !selectedValueOffice && !selectedValueTypeAppointment) {
            window.scrollTo(0, 0);
            const doctorId = docId;
            const specializationId = speId;
            setSpecialization(specializationId);
            const speciality = sc;
            setSpeciality(speciality);

            if(doctorId == null || specializationId == null || speciality == null){
                window.location.href = 'http://localhost:3000/home';
            }

            getDoctor(doctorId)
                .then((formattedData) => {
                    updateDoctor(formattedData);
                })
                .catch((error) => {
                    console.error('Errore durante il recupero dei dati:', error);
                });

            getInfoBlock(specializationId)
                .then((formattedData) => {
                    setInfo(formattedData);
                })
                .catch((error) => {
                    console.error('Errore durante il recupero dei dati:', error);
                });

            getPractitionerInfo(doctorId)
                .then((formattedData) => {
                    setPractitionerInfo(formattedData.practitionerInfo);
                })
                .catch((error) => {
                    console.error('Errore durante il recupero dei dati:', error);
                });

            getListOffice(specializationId)
                .then(async (formattedData) => {
                    const officeDetails = [];
                    for (const office of formattedData) {
                        try {
                            const officeInfo = await getOfficeInfo(specializationId, office.id);
                            if (officeInfo.data.services && Array.isArray(officeInfo.data.services) && officeInfo.data.services.some(service => service.id === config.codeOffice)) {
                                officeDetails.push(officeInfo);
                            }
                        } catch (error) {
                            console.error('Errore durante il recupero delle informazioni dell\'ambulatorio:', error);
                        }
                    }

                    setOffice(officeDetails);

                    const filteredOffices = officeDetails.map(item => item.data).filter(office => {
                        return office.services && Array.isArray(office.services);
                    });

                    setSelectValueOffice(filteredOffices);

                    if (initialLoad && filteredOffices.length === 1) {
                        setSelectedValueOffice(filteredOffices[0]);
                        setInitialLoad(false);
                    }

                })
                .catch((error) => {
                    console.error('Errore durante il recupero dei dati:', error);
                });

            getPractitionerAppointmentTypesList(specializationId)
                .then((formattedData) => {
                    const filteredData = formattedData.data.filter(item => item.patientVisible);
                    setSelectValueTypeAppointment(filteredData);
                })
                .catch((error) => {
                    console.error('Errore durante il recupero dei dati:', error);
                });

            getPractitionerSpecialization(doctorId)
                .then((formattedData) => {
                    setSelectValueSpecialization(formattedData.data);
                    setSelectedValueSpecialization(formattedData.data.find(item => item.specialization.id === speciality).specialization.id);
                    setSpecializationList(formattedData.data);
                })
                .catch((error) => {
                    console.error('Errore durante il recupero dei dati:', error);
                });
            
            setLoading(false);
        }

    }, [todayCount, selectedValueSpecialization, selectedValueOffice, selectedValueTypeAppointment]);

    useEffect(() => {
        if (selectedValueSpecialization && selectedValueOffice && selectedValueTypeAppointment) {
            Alert();
        }
    }, [selectedValueSpecialization, selectedValueOffice, selectedValueTypeAppointment]);
        
    const getDoctor = (doctorId) => {
        const url = `${config.apiUrl}/SMB/PractitionerInfo?PractitionerId=${doctorId}`;
        return fetch(url)
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                } else {
                    throw new Error("Response is not JSON");
                }
            })
            .then((responseData) => {
                return responseData.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                throw error;
            });
    };

    const checkHeight = () => {
        let clearfixElement;
        let buttonShow;
        if(window.innerWidth < 768) {
            if (listRefM.current) {
                clearfixElement = document.getElementById('clearfixM');
                buttonShow = document.getElementById('button-showM');
                if (clearfixElement) {
                    const originalMaxHeight = listRefM.current.style.maxHeight;
                    listRefM.current.style.maxHeight = 'none';
                    const listHeight = clearfixElement.scrollHeight;
                    listRefM.current.style.maxHeight = originalMaxHeight;

                    buttonShow.style.display = 'block';

                    if (listHeight > 270 && !isExpandedM) {
                        setTimeout(() => {
                            listRefM.current.style.maxHeight = '260px';
                        }, 0);
                    } else if (listHeight < 270) {
                        setTimeout(() => {
                            listRefM.current.style.maxHeight = 'none';
                            buttonShow.style.display = 'none';
                        }, 0);
                    }
                }
            }
        }else{
            if (listRef.current) {
                clearfixElement = document.getElementById('clearfix');
                buttonShow = document.getElementById('button-show');
                if (clearfixElement) {
                    const originalMaxHeight = listRef.current.style.maxHeight;
                    listRef.current.style.maxHeight = 'none';
                    const listHeight = clearfixElement.scrollHeight;
                    listRef.current.style.maxHeight = originalMaxHeight;

                    buttonShow.style.display = 'block';
                    
                    if (listHeight > 270 && !isExpanded) {
                        setTimeout(() => {
                            listRef.current.style.maxHeight = '260px';
                        }, 0);
                    } else if (listHeight < 270) {
                        setTimeout(() => {
                            listRef.current.style.maxHeight = 'none';
                            buttonShow.style.display = 'none';
                        }, 0);
                    }
                }
            }
        }
    };


    useEffect(() => {
        const observer = new MutationObserver(() => {
            requestAnimationFrame(checkHeight);
        });

        if(window.innerWidth < 768) {
            if (listRefM.current) {
                observer.observe(listRefM.current, {childList: true, subtree: true});
            }
        }else{
            if (listRef.current) {
                observer.observe(listRef.current, { childList: true, subtree: true });
            }
        }

        requestAnimationFrame(checkHeight);

        return () => {
            if(window.innerWidth < 768) {
                if (listRefM.current) {
                    observer.disconnect();
                }
            }else{
                if (listRef.current) {
                    observer.disconnect();
                }
            }
        };
    }, [availability, isExpanded, isExpandedM]);
    
    const getInfoBlock = (specializationId) => {
        const url = `${config.apiUrl}/SMB/PractitionerInfoBlock?PractitionerSpecializationId=${specializationId}`;
        return fetch(url)
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                } else {
                    throw new Error("Response is not JSON");
                }
            })
            .then((responseData) => {
                return responseData.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                throw error;
            });
    };

    const getPractitionerInfo = (doctorId) => {
        const url = `${config.apiUrl}/SMB/PractitionerInfo?PractitionerId=${doctorId}`;
        return fetch(url)
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                } else {
                    throw new Error("Response is not JSON");
                }
            })
            .then((responseData) => {
                return responseData.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                throw error;
            });
    };

    const getListOffice = async (specializationId) => {
        try {
            
            const url = `${config.apiUrl}/SMB/PractitionerOfficeList?PractitionerSpecializationId=${specializationId}`;
            const response = await fetch(url);
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                const data = await response.json();
                return data.data;
            } else {
                throw new Error("Response is not JSON");
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getOfficeInfo = async (practitionerSpecializationId, officeId) => {
        try {
            const url = `${config.apiUrl}/SMB/OfficeInfo?PractitionerSpecializationId=${practitionerSpecializationId}&OfficeId=${officeId}`;
            const response = await fetch(url);
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                const data = await response.json();
                return data;
            } else {
                throw new Error("Response is not JSON");
            }
        } catch (error) {
            console.error('Error fetching office info:', error);
            throw error;
        }
    };
    const getPractitionerSpecialization = async (DoctorId) => {
        try {
            const url = `${config.apiUrl}/SMB/PractitionerSpecialization?PractitionerId=${DoctorId}`;
            const response = await fetch(url);
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                const data = await response.json();
                return data;
            } else {
                throw new Error("Response is not JSON");
            }
        } catch (error) {
            console.error('Error fetching office info:', error);
            throw error;
        }
    };

    const getPractitionerAppointmentTypesList = async (practitionerSpecializationId) => {
        try {
            const url = `${config.apiUrl}/SMB/PractitionerAppointmentTypesList?PractitionerSpecializationId=${practitionerSpecializationId}`;
            const response = await fetch(url);
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                const data = await response.json();
                return data;
            } else {
                throw new Error("Response is not JSON");
            }
        } catch (error) {
            console.error('Error fetching office info:', error);
            throw error;
        }
    };

    const getPractitionerApointmentsByInterval = async (practitionerSpecializationId, office) => {
        try {
            const startOfWeek = new Date(todayCount);
            startOfWeek.setDate(todayCount.getDate() - 1);
            const endOfWeek = new Date(startOfWeek);

            if(window.innerWidth < 768){
                endOfWeek.setDate(startOfWeek.getDate() + 2);
            }else{
                endOfWeek.setDate(startOfWeek.getDate() + 5);
            }

            const startDateFormatted = startOfWeek.toISOString().split('T')[0]; // Ottiene YYYY-MM-DD
            const endDateFormatted = endOfWeek.toISOString().split('T')[0]; // Ottiene YYYY-MM-DD

            const url = `${config.apiUrl}/SMB/PractitionerApointmentsByInterval?PractitionerSpecializationId=${practitionerSpecializationId}&StartDate=${startDateFormatted}&EndDate=${endDateFormatted}&OfficeId=${office}`;

            const response = await fetch(url);
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                const data = await response.json();
                return data;
            } else {
                throw new Error("Response is not JSON");
            }
        } catch (error) {
            console.error('Error fetching office info:', error);
            throw error;
        }
    };

    const getAlert = (alertType, practitionerSpecializationId, scheduleAppointmentTypeId) => {
        const url = `${config.apiUrl}/SMB/GetAlert?alertType=${alertType}&practitionerSpecilizationId=${practitionerSpecializationId}&appointmentTypeId=${scheduleAppointmentTypeId}`;
        return fetch(url)
            .then((response) => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                } else {
                    throw new Error("Response is not JSON");
                }
            })
            .then((responseData) => {
                return responseData;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                throw error;
            });
    };

    return (
        <>
            <div className="main-wrapper">
                <Header {...props} />
                <div className="breadcrumb-bar-two">
                    <div className="container">
                        <div className="row align-items-center inner-banner">
                            <div className="col-md-12 col-12 text-center">
                                <h2 className="breadcrumb-title">Profilo dottore</h2>
                            </div>
                        </div>
                    </div>
                </div>
                {popupData && (
                    <AlertPopup
                        title={popupData.title}
                        body={popupData.body}
                        backgroundColor={popupData.backgroundColor}
                        textColor={popupData.textColor}
                        onClose={handleClosePopup}
                    />
                )}
                <>
                    {/* Page Content */}
                    <div className="content">
                    <style>
                        {`                                                    
                        .loader {
                            width: 150px;
                            position: fixed;
                            top: 50%;
                            left: 48%;
                            transform: translate(-50%, -50%); /* Per centrare l'immagine */
                            animation: pulse 1s infinite; /* Animazione di pulsazione */
                            z-index: 99999;
                        }

                        @media only screen and (max-width: 600px) {
                            .loader {
                                left: 38%;
                            }
                        }

                        @keyframes pulse {
                            0% {
                                transform: scale(1); /* Dimensione iniziale */
                            }
                            50% {
                                transform: scale(1.1); /* Ingrandimento */
                            }
                            100% {
                                transform: scale(1); /* Torna alla dimensione originale */
                            }
                        }

                        .buttons-container {
                            display: flex;
                            justify-content: flex-end;
                        }                    
                        
                        .button-option-availability{
                            background-color: #9FE6F6;
                            color: #0B9CBD;
                            border: 0px;
                            border-radius: 100px;
                            padding: 0px 8px 3px 8px;
                            font-size: 25px;
                            margin-right: 5px;
                            margin-left: 5px;
                            margin-top: -10px;
                        }             
                        
                        .schedule-widget .schedule-header{
                            border-bottom: 0px !important;
                            padding: 5px 15px;
                        }    
                        
                        .schedule-cont{
                            padding: 5px 10px;
                        }     
                        
                        .day-slot ul li span.slot-date{
                            font-size: 12px;
                        }
                        
                        .day-slot ul li{
                            padding-left: 2px !important;
                            padding-right: 2px !important;
                        }
                        
                        .no-availability{
                            font-size: 12px;
                        }
                        
                        .option-border{
                            border-top: 1px solid #CBD5E1;
                            padding-top: 20px;
                        }
                        
                        .day-slot ul li, .time-slot ul li {
                            width: 16.66% !important;
                            margin-top: 15px;
                        }     
                            
                        .overlay {
                            position: fixed; 
                            left: 0; 
                            width: 100%;
                            height: 110%;
                            top: -10%;
                            background-color: rgba(0, 0, 0, 0.5); /* Colore semi-trasparente */
                            z-index: 9999; /* Assicurati che l'overlay sia sotto il loader ma sopra il contenuto */
                        }
                        
                        .slot-option {
                            margin-bottom: 5px;
                            background-color: #9FE6F6;
                            padding: 3px 8px;
                            border-radius: 4px;
                            display: inline-block;
                            margin-right: 5px;
                            font-size: 13px;
                            transition: background-color 0.3s ease; /* Opzionale: aggiunge una transizione dolce */
                        }
                        
                        .slot-option:hover {
                            background-color: #7FC9D8; /* Colore di sfondo diverso quando l'elemento è in hover */
                            cursor: pointer; /* Cambia il cursore per indicare che l'elemento è interattivo */
                        }
                        
                        .clearfixM, 
                        .clearfix{
                            overflow: hidden;
                            transition: max-height 0.5s ease; /
                        }
                                                    
                              .header.header - trans{
                              position: relative;
                          }
                              #bussiness_hour{
                              border-bottom: 0px !important;
                          }

                              .baseline-important {
                              align-items: baseline !important;
                          }

                              .doc-info-right{
                              margin-left: 0px !important;
                              margin-bottom: 0px !important;
                          }

                              .doctors-detailed-info ul{
                              padding-left: 0px;
                          }

                              .clinic-img{
                              display: none;
                          }

                              .clinic-loc{
                              padding: 10px;
                          }

                              .sticky-option{
                              position: sticky; 
                              top: 110px;
                          }

                              .color-header{
                              background-color: #0fc2ea !important;
                              color: white !important;
                          }

                          .custom-prime-select{
                              border: 1px solid  #1C244B !important;
                              background: #1C244B;
                              color: lightgrey;
                              margin-top: 10px !important;
                              border-radius: 10px !important;
                              padding-right: 10px !important;
                              padding-left: 10px !important;
                              position: relative; /* Mantiene il contesto per il dropdown */
                              width: 100%;
                            }
                            
                            .custom-prime-select .p-dropdown-label {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 100%; /* o una larghezza specifica, es: 200px */
                                color: white;
                            }
                            
                            .custom-dropdown .p-dropdown-item {
                                color: grey;
                            }

                            .custom-dropdown .p-dropdown-item.p-highlight {
                                background-color: #1C244B !important; /* Sfondo blu scuro per l'opzione selezionata */
                                color: white !important; /* Colore del testo nero per l'opzione selezionata */
                            }

                          .custom-dropdown {
                              position: absolute; /* Posiziona il dropdown in modo assoluto */
                              top: 100%; /* Posiziona il dropdown sotto la select */
                              left: 0; /* Allinea il dropdown con la select */
                              z-index: 1000; /* Assicura che sia sopra altri elementi */
                              background-color: white !important; /* Sfondo dropdown blu scuro */
                              border-radius: 10px;
                          }

                              .special-links li a{
                              padding: 8px 10px !important;
                          }

                              .specialization-option{
                              color: white !important;
                              background-color: #1c244b !important;
                              font-weight: 500;
                              border: 0px !important;
                          }
                          
                          .information-title-list li a:hover{
                            background-color: #1c244b !important;
                            border-color: #1c244b !important;
                          }
                          
                          .doc-profile-card-bottom ul li span.bg-blue{
                            background-color: #1c244b !important;
                          }
                          
                          .information-title-list li.active a{
                            background-color: #1c244b !important;
                            border-color: #1c244b !important;
                          }

                            .option-a{
                              background: #F1F5F9;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              padding: 10px 15px;
                              border-radius: 10px;
                              font-size: 14px;
                              color: #000000;
                              font-weight: 500;
                          }

                              .option-fb-in, .option-fb-in:hover{
                              background: #F1F5F9;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              padding: 10px 15px;
                              border-radius: 10px;
                              font-size: 14px;
                              color: #000000;
                              font-weight: 500;
                          }

                              .vertical-layout {
                              display: flex;
                              flex-direction: column;
                              gap: 10px; /* Spaziatura tra gli elementi */
                          }
                          
                           .schedule-widget{
                              width: 102.5%;
                           }

                          @media only screen and (max-width: 600px) {
                              .doc-info-right{
                                  float: left;
                              }
                              
                              .doctor-widget.doctor-profile-two,
                              .doctor-widget.doctor-profile-two .doc-info-left{
                                align-items: center !important;
                              }
    
                              ul{
                                  padding-left: 0px !important;
                              }
    
                                  .information-title-list{
                                  font-size: 14px !important;
                              }
    
                                  .doc-profile-card-bottom .bottom-book-btn .clinic-booking .apt-btn{
                                  margin-left: 0px !important;
                              }
    
                                  .doc-profile-card-bottom .bottom-book-btn{
                                  width: 100% !important;
                              }
    
                                  .row{
                                  width: 100%;
                              }
    
                                  .container{
                                  padding-right: 0px !important;
                              }
    
                                  .custom-prime-select.p-dropdown.p-component.p-inputwrapper{
                                  width: 78vw !important;
                                  font-size: 14px !important;
                              }
    
                                  .day-slot ul li span.slot-date{
                                  font-size: 14px !important;
                              }
    
                                  .booking-schedule.schedule-widget{
                                  overflow-x: hidden;
                                  -webkit-overflow-scrolling: touch;
                                  width: 100%;
                              }
    
                                  .buttons-container {
                                  padding-right: 15%;
                              }
                              
                              .booking-schedule.schedule-widget > div{
                                    width: 630px;
                                }
                                
                              .vertical-layout {
                                  flex-direction: row;
                              }
                          }

                              `}
                        </style>
                        <div className="container d-flex">
                            <div className="row">
                                <div className="col-12 col-md-7">
                                    <div className="container">
                                        {/* Doctor Widget */}
                                        <div className="card doc-profile-card">
                                            <div className="card-body">
                                                <div className="doctor-widget doctor-profile-two baseline-important">
                                                    <div className="doc-info-left">
                                                        <div className="doctor-img">
                                                            {practitionerInfo && practitionerInfo?.practitionerImages && practitionerInfo.practitionerImages?.length > 0 && doctor.practitionerInfo.practitionerImages[0].url !== null ? (
                                                                <img src={practitionerInfo.practitionerImages[0].url}
                                                                     className="img-fluid" alt=""/>
                                                            ) : (
                                                                <img
                                                                    src="/assets/img/doctor-placeholder.png"
                                                                    className="img-fluid" alt=""
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="doc-info-cont">
                                                            <span className="badge doc-avail-badge">
                                                            <i className="fa-solid fa-circle"/>
                                                                {selectValueSpecialization.length > 0 ? (
                                                                    selectValueSpecialization.map((item, index) => (
                                                                        item.specialization && item.specialization.id === specialityId ? (
                                                                            <>{item.specialization.description}</>
                                                                        ) : null
                                                                    ))
                                                                ) : (
                                                                    <p></p>
                                                                )}{" "}
                                                            </span>
                                                            <h4 className="ms-2 ms-md-0 doc-name">
                                                                {doctor && typeof doctor.lastName === "string" && doctor.lastName.trim() !== "" ? (
                                                                    `Dr. ${doctor.lastName} ${doctor.firstName}`
                                                                ) : ''}
                                                                <ImageWithBasePath src="assets/img/icons/badge-check.svg" alt="Img"/>
                                                                <span className="badge doctor-role-badge d-none">
                        <i className="fa-solid fa-circle"/>
                        test
                      </span>
                                                            </h4>
                                                            <p className="d-none">BDS, MDS - Oral &amp; Maxillofacial
                                                                Surgery</p>
                                                            <p className="d-none">Speaks : English, French, German</p>
                                                            <p className="address-detail d-none">
                      <span className="loc-icon">
                        <i className="feather-map-pin"/>
                      </span>
                                                                No 14, 15th Cross, Old Trafford, UK{" "}
                                                                <span className="view-text d-none">( View Location )</span>
                                                            </p>
                                                            <div className="mt-0 mt-md-5 pt-3 d-flex">
                                                                {practitionerInfo && practitionerInfo.facebook && (
                                                                    <a className="option-fb-in" target="_blank" href={practitionerInfo.facebook}>
                                                                        <i className="fab fa-facebook"/>{" "}
                                                                    </a>
                                                                )}
                                                                {practitionerInfo && practitionerInfo.instagram && (
                                                                    <a className="ms-3 option-fb-in" target="_blank"
                                                                       href={practitionerInfo.instagram}> <i className="fab fa-instagram"/>
                                                                    </a>
                                                                )}
                                                                {practitionerInfo && practitionerInfo.website && (
                                                                    <a className="ms-3 option-fb-in" target="_blank" href={practitionerInfo.website}><i className="fa-solid fa-globe"></i>
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="doc-info-right">
                                                        <ul className="doctors-activities">
                                                            <li className={"d-none"}>
                                                                <div className="hospital-info">
                        <span className="list-icon">
                          <ImageWithBasePath src="assets/img/icons/watch-icon.svg" alt="Img"/>
                        </span>
                                                                    <p>Full Time, Online Therapy Available</p>
                                                                </div>
                                                                <ul className="sub-links">
                                                                    <li>
                                                                        <a href="#">
                                                                            <i className="feather-heart"/>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#">
                                                                            <i className="feather-share-2"/>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#">
                                                                            <i className="feather-link"/>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li className={"d-none"}>
                                                                <div className="hospital-info">
                        <span className="list-icon">
                          <ImageWithBasePath src="assets/img/icons/thumb-icon.svg" alt="Img"/>
                        </span>
                                                                    <p>
                                                                        <b>94% </b> Recommended
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li className={"d-none"}>
                                                                <div className="hospital-info">
                        <span className="list-icon">
                          <ImageWithBasePath src="assets/img/icons/building-icon.svg" alt="Img"/>
                        </span>
                                                                    <p>Royal Prince Alfred Hospital</p>
                                                                </div>
                                                                <h5 className="accept-text">
                        <span>
                          <i className="feather-check"/>
                        </span>
                                                                    Accepting New Patients
                                                                </h5>
                                                            </li>
                                                            <li>
                                                                <div className="rating d-none">
                                                                    <i className="fas fa-star filled"/>
                                                                    <i className="fas fa-star filled"/>
                                                                    <i className="fas fa-star filled"/>
                                                                    <i className="fas fa-star filled"/>
                                                                    <i className="fas fa-star filled"/>
                                                                    <span>5.0</span>
                                                                    <a href="#" className="d-inline-block average-rating">
                                                                        150 Reviews
                                                                    </a>
                                                                </div>
                                                                <ul className="contact-doctors">
                                                                    <li className={"invisible d-none d-md-block"}>
                                                                        <a href="/doctor/chat-doctor">
                                                                    <span>
                                                                      <ImageWithBasePath
                                                                          src="assets/img/icons/device-message2.svg"
                                                                          alt="Img"
                                                                      />
                                                                    </span>
                                                                            Chat
                                                                        </a>
                                                                    </li>
                                                                    <li>
    
                                                                    </li>
                                                                    <li>
                                                                        {doctor && doctor.practitionerContactDTO && doctor.practitionerContactDTO.patientMobileNumber ? (
                                                                            <a className="mt-1 mt-md-5"
                                                                               href={`tel:${doctor.practitionerContactDTO.patientMobileNumber}`}>
                                                                            <span className="bg-violet">
                                                                                <i class="fa-solid fa-phone"></i>
                                                                            </span>
                                                                                Chiama il dottore
                                                                            </a>
                                                                        ) : null}
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="doc-profile-card-bottom">
                                                    <ul>
                                                        <li>
                                                            <span className="bg-blue">
                                                                <ImageWithBasePath src="assets/img/icons/calendar3.svg" alt="Img"/>
                                                            </span>
                                                            {(() => {
                                                                const isValid = (value) => Array.isArray(value) && value.length > 0;

                                                                const isAcceptingBookings =
                                                                    isValid(selectValueTypeAppointment) &&
                                                                    isValid(selectValueOffice) &&
                                                                    isValid(selectValueSpecialization);

                                                                return isAcceptingBookings ? "Accetta prenotazioni online" : "Non accetta prenotazioni online";
                                                            })()}
                                                        </li>
                                                        {/* <li>
                                                            <span className="bg-green">
                                                              <ImageWithBasePath src="assets/img/icons/bookmark-star.svg" alt="Img" />
                                                            </span>
                                                            15+ Awards
                                                          </li>*/}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-block d-md-none" id="appointment">
                                            <div className="card doc-profile-card">
                                                <div className="card-header color-header">
                                                    <h4>Prenota una visita</h4>
                                                </div>
                                            <div className="card-body">
                                                <div className="doctor-widget doctor-profile-two baseline-important row">
                                                    <div className="col-12 doctor-form">
                                                        <p>Seleziona le opzioni per visualizzare le disponibilità</p>
                                                    </div>
                                                    <div className="col-12 mb-2 doctor-form">
                                                        <form action="#" className="doctor-search">
                                                            <div className="input-box-twelve row">
                                                                <div className="col-12 search-input input-block">
                                                                    <Dropdown
                                                                        value={selectedValueSpecialization}
                                                                        onChange={handleSpecializationChange}
                                                                        options={selectValueSpecialization}
                                                                        optionLabel="specialization.description"
                                                                        optionValue="specialization.id"
                                                                        placeholder="Seleziona specializzazione"
                                                                        className="custom-prime-select"
                                                                        panelClassName="custom-dropdown"
                                                                        appendTo="self"
                                                                    />
    
                                                                    <Link className="current-loc-icon current_location"
                                                                          to="#"></Link>
                                                                </div>
    
                                                                <div className="col-12 search-input input-block">
                                                                    <Dropdown
                                                                        value={selectedValueOffice}
                                                                        onChange={handleOfficeChange}
                                                                        options={selectValueOffice}
                                                                        optionLabel="name"
                                                                        placeholder="Seleziona ambulatorio"
                                                                        className="custom-prime-select"
                                                                        panelClassName="custom-dropdown"
                                                                        appendTo="self"
                                                                    />
                                                                    <Link className="current-loc-icon current_location"
                                                                          to="#">
                                                                    </Link>
                                                                </div>
    
                                                                <div className="col-12 mb-2 search-input input-block">
                                                                    <Dropdown
                                                                        value={selectedValueTypeAppointment}
                                                                        onChange={handleTypeAppointmentChange}
                                                                        options={selectValueTypeAppointment}
                                                                        optionLabel="description"
                                                                        placeholder="Tipologia di appuntamento"
                                                                        className="custom-prime-select"
                                                                        panelClassName="custom-dropdown"
                                                                        appendTo="self"
                                                                    />
                                                                    <Link className="current-loc-icon current_location"
                                                                          to="#">
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    {(!selectValueTypeAppointment || selectValueTypeAppointment.length === 0 ||
                                                        !selectValueOffice || selectValueOffice.length === 0 ||
                                                        !selectValueSpecialization || selectValueSpecialization.length === 0)
                                                        ? <div id="error-message" className="alert alert-danger mt-3" style={{display: 'block'}} role="alert">
                                                            Non accetta prenotazioni online su 3SMB
                                                            </div>
                                                        : ""
                                                        }
                                                </div>
                                                <div>
                                                    {alerts && alerts.map((alert, index) => (
                                                        <div
                                                            key={index}
                                                            id={`alert-${alert.alertId}`}
                                                            className="alert alert-success mt-2"
                                                            role="alert"
                                                            style={{
                                                                backgroundColor: alert.backgroundColor,
                                                                color: alert.textColor,
                                                                borderColor: alert.borderColor,
                                                                padding: '10px 10px 0px 10px'
                                                            }}
                                                        >
                                                            <h6>{alert.title}</h6>
                                                            <p>{alert.body}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={`vertical-layout option-border ${isVisible ? '' : 'd-none'}`}>
                                                    <div className="mt-2 booking-schedule schedule-widget ps-0">
                                                        <div className="schedule-header">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="day-slot vertical-layout">
                                                                        {availability && availability.length > 0 ? (
                                                                            <div>
                                                                                <div className="row">
                                                                                    <div className="col-8 col-md-9">
                                                                                        Seleziona e prenota il tuo appuntamento:
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-3 buttons-container">
                                                                                        <button
                                                                                            className="button-option-availability"
                                                                                            onClick={goToPreviousWeek}>{"<"}</button>
                                                                                        <button
                                                                                            className="button-option-availability"
                                                                                            onClick={goToNextWeek}>{">"}</button>
                                                                                    </div>
                                                                                </div>
                                                                                <ul>
                                                                                    {availability.map((item, index) => (
                                                                                        <li key={index}>
                                                                                          <span
                                                                                              style={{fontSize: '10px'}}>
                                                                                              {['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'][item.dayOfWeek - 1]}
                                                                                          </span>
                                                                                            <span className="slot-date">
                                                                                          {new Date(item.day).toLocaleDateString('it-IT', {
                                                                                              day: 'numeric',
                                                                                              month: 'short'
                                                                                          })}
                                                                                        </span>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        ) : (
                                                                            <p>Nessuna disponibilità</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="schedule-cont">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="time-slot">
                                                                        <ul className="clearfixM" id="clearfixM"
                                                                            ref={listRefM}>
                                                                            {availability && availability
                                                                                .map((item, index) => {
                                                                                    const itemDate = new Date(item.day);
                                                                                    const todayDate = new Date();

                                                                                    itemDate.setHours(0, 0, 0, 0);
                                                                                    todayDate.setHours(0, 0, 0, 0);
                                                                                    
                                                                                    return (
                                                                                    <li key={index}
                                                                                        style={{marginBottom: '10px'}}>
                                                                                        {item.slotsByOffice && itemDate >= todayDate && item.slotsByOffice.length > 0 ? (
                                                                                            item.slotsByOffice.flatMap((office, officeIndex) => {
                                                                                                let hasAvailableSlots = false;
                                                                                                const slots = office.blocks.flatMap((block, blockIndex) => {
                                                                                                    if (block && block.scheduleAvailability && block.scheduleAvailability.scheduleAppointmentCategory && block.scheduleAvailability.scheduleAppointmentCategory.patientVisible) {
                                                                                                        const freeTimes = block.freeTimes;
                                                                                                        let subSlots = [];
    
                                                                                                        if (freeTimes) {
                                                                                                            freeTimes.forEach(freeTime => {
                                                                                                                const generatedSlots = generateSubSlots(
                                                                                                                    freeTime.startTime,
                                                                                                                    freeTime.endTime,
                                                                                                                    itemDate
                                                                                                                );
                                                                                                                subSlots = subSlots.concat(generatedSlots);
                                                                                                            });
                                                                                                        }
    
                                                                                                        return subSlots.map((slot, slotIndex) => (
                                                                                                            <div
                                                                                                                className="slot-option"
                                                                                                                key={`${officeIndex}-${blockIndex}-${slotIndex}`}>
                                                                                                                <a href={`/checkout/${slot.start}/${slot.end}/${item.day}/${doctor.practitionerId}/${specializationId}/${selectedValueOffice.id}/${selectedValueTypeAppointment.scheduleAppointmentTypeId}`}>
                                                                                                                    <span>{slot.start}</span>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        ));
                                                                                                    } else if (block && !block.scheduleAvailability) {
                                                                                                        hasAvailableSlots = true;
                                                                                                    }
                                                                                                    return [];
                                                                                                });
    
                                                                                                return (
                                                                                                    <React.Fragment
                                                                                                        key={officeIndex}>
                                                                                                        {slots}
                                                                                                        {!hasAvailableSlots &&
                                                                                                            <p className="no-availability">-</p>}
                                                                                                    </React.Fragment>
                                                                                                );
                                                                                            })
                                                                                        ) : (
                                                                                            <p className="no-availability">-</p>
                                                                                        )}
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            width: '55%'
                                                                        }}>
                                                                            <button
                                                                                onClick={toggleContent}
                                                                                id="button-showM"
                                                                                style={{
                                                                                    marginTop: '10px',
                                                                                    border: 'none',
                                                                                    padding: '5px 10px',
                                                                                    borderRadius: '5px',
                                                                                    cursor: 'pointer',
                                                                                    display: 'inline-block',
                                                                                }}>
                                                                                {isExpandedM ? (
                                                                                    <>
                                                                                        Mostra Meno <i
                                                                                        className="fas fa-chevron-up"
                                                                                        style={{marginLeft: '10px'}}></i>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        Mostra Tutti <i
                                                                                        className="fas fa-chevron-down"
                                                                                        style={{marginLeft: '10px'}}></i>
                                                                                    </>
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        {/* /Doctor Widget */}
                                        <div className="doctors-detailed-info">
                                            <ul className="information-title-list">
                                                <li className="active">
                                                    <a href="#doc_bio">Biografia</a>
                                                </li>
                                                <li>
                                                    <a href="#info">Informazioni</a>
                                                </li>
                                                <li className="d-none">
                                                    <a href="#insurence">Insurances</a>
                                                </li>
                                                <li className="d-none">
                                                    <a href="#services">Treatments</a>
                                                </li>
                                                <li>
                                                    <a href="#speciality">Specializzazioni</a>
                                                </li>
                                                <li>
                                                    <a href="#clinic">Cliniche</a>
                                                </li>
                                                <li>
                                                    <a href="#experience">Esperienze</a>
                                                </li>
                                                <li className="d-none">
                                                    <a href="#membership">Memberships</a>
                                                </li>
                                                <li className="d-none">
                                                    <a href="#awards">Awards</a>
                                                </li>
                                                <li className="d-none">
                                                    <a href="#review">Review</a>
                                                </li>
                                            </ul>
                                            <div className="doc-information-main">
                                                {practitionerInfo && practitionerInfo.bioDescription && (
                                                    <div className="doc-information-details bio-detail" id="doc_bio">
                                                        <>
                                                            <div className="detail-title">
                                                                <h4>Biografia</h4>
                                                            </div>
                                                            <p>
                                                                <div>
                                                                    <p>{practitionerInfo.bioDescription}</p>
                                                                </div>
                                                            </p>
                                                        </>
                                                    </div>
                                                )}
                                                {info && info.length > 0 ? (
                                                    <div className="doc-information-details bio-detail" id="info">
                                                        <div>
                                                            <div className="detail-title">
                                                                <h4>Informazioni</h4>
                                                            </div>
                                                            {info.map((informazioni, index) => (
                                                                informazioni.infoBlock.visibility === "ALL" ? (
                                                                    <div key={index}>
                                                                        <h6>{informazioni.infoBlock.name}</h6>
                                                                        <p>
                                                                            {informazioni.description.split('\n').map((line, lineIndex) => (
                                                                                <React.Fragment key={lineIndex}>
                                                                                    {line}
                                                                                    {lineIndex < informazioni.description.split('\n').length - 1 &&
                                                                                        <br/>}
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </p>
                                                                    </div>
                                                                ) : null
                                                            ))}
                                                        </div>
                                                    </div>
                                                    ) : (
                                                        <p></p>
                                                    )
                                                }
                                                <div className="doc-information-details d-none" id="insurence">
                                                    <div
                                                        className="detail-title slider-nav d-flex justify-content-between align-items-center">
                                                        <h4>Insurance Accepted (6)</h4>
                                                        <div className="nav nav-container slide-1"/>
                                                    </div>
                                                    <OwlCarousel {...ourlog}
                                                                 className="insurence-logo-slider owl-carousel">
                                                        <div className="insurence-logo">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/insurence-logo-01.svg" alt="Img"/>
                    </span>
                                                        </div>
                                                        <div className="insurence-logo">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/insurence-logo-02.svg" alt="Img"/>
                    </span>
                                                        </div>
                                                        <div className="insurence-logo">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/insurence-logo-03.svg" alt="Img"/>
                    </span>
                                                        </div>
                                                        <div className="insurence-logo">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/insurence-logo-04.svg" alt="Img"/>
                    </span>
                                                        </div>
                                                        <div className="insurence-logo">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/insurence-logo-05.svg" alt="Img"/>
                    </span>
                                                        </div>
                                                        <div className="insurence-logo">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/insurence-logo-06.svg" alt="Img"/>
                    </span>
                                                        </div>
                                                        <div className="insurence-logo">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/insurence-logo-03.svg" alt="Img"/>
                    </span>
                                                        </div>
                                                        <div className="insurence-logo">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/insurence-logo-04.svg" alt="Img"/>
                    </span>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                                <div className="doc-information-details" id="speciality">
                                                    <div className="detail-title">
                                                        <h4>Specializzazioni</h4>
                                                    </div>
                                                    {selectValueSpecialization.length > 0 ? (
                                                        <ul className="special-links">
                                                            {selectValueSpecialization.map((item, index) => (
                                                                <li key={index}>
                                                                    {item.specialization && item.specialization.id === specialityId ? (
                                                                        <a href="#" className="specialization-option"
                                                                           onClick={(e) => {
                                                                               e.preventDefault();
                                                                               updatePage(item.specialization.id);
                                                                           }}>
                                                                            {item.specialization.description}
                                                                        </a>
                                                                    ) : (
                                                                        <a href="#" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            updatePage(item.specialization.id);
                                                                        }}>
                                                                            {item.specialization.description}
                                                                        </a>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <p>Nessuna specializzazione trovata</p>
                                                    )}
                                                </div>
                                                <div className="doc-information-details d-none" id="services">
                                                    <div className="detail-title">
                                                        <h4>Services &amp; Pricing</h4>
                                                    </div>
                                                    <ul className="special-links">
                                                        <li>
                                                            <a href="#">
                                                                Orthopedic Consultation <span>$52</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                Delivery Blocks <span>$24</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                Ultrasound Injection <span>$31</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                Tooth Bleaching <span>$20</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                Tooth Bleaching <span>$15</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                Cosmetic <span>$10</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="doc-information-details d-none" id="availability">
                                                    <div
                                                        className="detail-title slider-nav d-flex justify-content-between align-items-center">
                                                        <h4>Disponibilità</h4>
                                                        <div className="nav nav-container slide-2"/>
                                                    </div>
                                                </div>
                                                <div className="doc-information-details" id="clinic">
                                                    <div className="detail-title">
                                                        <h4>Cliniche</h4>
                                                    </div>
                                                    {office && office.length > 0 ? (
                                                        office.map((office, index) => (
                                                            <div className="clinic-loc">
                                                                <div className="row align-items-center">
                                                                    <div className="col-lg-5">
                                                                        <div className="clinic-info">
                                                                            <div className="clinic-img">
                                                                            </div>
                                                                            <div className="detail-clinic">
                                                                                <h5>{office.data.name}</h5>
                                                                                {/*<span>$350 / Apponitment</span>*/}
                                                                                <p>{office.data.address}</p>
                                                                                <p>{office.data.province?.description}</p>
                                                                                <p>{office.data.postalCode}</p>
                                                                                <div className="mt-1">
                                                                                    {office?.data?.contact?.email ? (
                                                                                        <a className="mt-2 option-a"
                                                                                           href={`mailto: ${office?.data?.contact?.email}`}>
                                                                                        <span className="me-1">
                                                                                          <ImageWithBasePath
                                                                                              src="assets/img/icons/device-message2.svg"
                                                                                              alt="Img"
                                                                                          />
                                                                                        </span>
                                                                                            Scrivi
                                                                                        </a>
                                                                                    ) : null}
    
                                                                                    {office?.data?.contact?.phoneNumber ? (
                                                                                        <a className="mt-2 option-a"
                                                                                           href={`tel:${office?.data?.contact?.phoneNumber}`}>
                                                                                        <span className="bg-violet me-1">
                                                                                            <i class="fa-solid fa-phone"></i>
                                                                                        </span>
                                                                                            {office?.data?.contact?.phoneNumber}
                                                                                        </a>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex align-items-center avail-time-slot">
                                                                            <div className="availability-date">
                                                                                {/*<div className="book-date">
                                <h6>Monday</h6>
                                <span>07:00 AM - 09:00 PM</span>
                                </div>*/}
                                                                            </div>
                                                                            <div className="availability-date">
                                                                                {/*<div className="book-date">
                                <h6>Tuesday</h6>
                                <span>07:00 AM - 09:00 PM</span>
                                </div>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-7">
                                                                        <div className="contact-map d-flex">
                                                                            <iframe
                                                                                width="100%"
                                                                                height="300"
                                                                                frameBorder="0"
                                                                                src={`https://www.google.com/maps/embed/v1/place?key=${config.apiKeyMaps}&q=${encodeURIComponent(`${office?.data?.address}, ${office?.data?.province?.description}, ${office?.data?.postalCode}`)}`}
                                                                                allowFullScreen=""
                                                                                loading="lazy"
                                                                                referrerPolicy="no-referrer-when-downgrade"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>Nessun ambulatorio trovato</p>
                                                    )}
                                                </div>
                                                {practitionerInfo && (practitionerInfo.education || practitionerInfo.specialization || practitionerInfo.certificate || practitionerInfo.internship || practitionerInfo.award || (practitionerInfo.practitionerPathologies && practitionerInfo.practitionerPathologies.length !== 0)) && (
                                                    <>
                                                        <div className="doc-information-details bio-detail"
                                                             id="experience">
                                                            <div className="detail-title">
                                                                <h4>Esperienze</h4>
                                                            </div>
                                                            {practitionerInfo.specialization && (
                                                                <div className="mt-4">
                                                                    <h6><i className="fa-solid fa-user-doctor me-1"></i>Specializzazioni
                                                                    </h6>
                                                                    <p>{practitionerInfo.specialization}</p>
                                                                </div>
                                                            )}
                                                            {practitionerInfo.education && (
                                                                <div className="mt-4">
                                                                    <h6><i className="fa-solid fa-school me-1"></i>Educazione
                                                                    </h6>
                                                                    <p>{practitionerInfo.education}</p>
                                                                </div>
                                                            )}
                                                            {practitionerInfo.certificate && (
                                                                <div className="mt-4">
                                                                    <h6><i className="fa-solid fa-stamp me-1 me-1"></i>Certificati
                                                                    </h6>
                                                                    <p>{practitionerInfo.certificate}</p>
                                                                </div>
                                                            )}
                                                            {practitionerInfo.internship && (
                                                                <div className="mt-4">
                                                                    <h6><i className="fa-solid fa-book me-1"></i>Tirocini
                                                                    </h6>
                                                                    <p>{practitionerInfo.internship}</p>
                                                                </div>
                                                            )}
                                                            {practitionerInfo.award && (
                                                                <div className="mt-4">
                                                                    <h6><i className="fa-solid fa-award me-1"></i>Titoli di studio
                                                                    </h6>
                                                                    <p>{practitionerInfo.award}</p>
                                                                </div>
                                                            )}
                                                            {practitionerInfo.practitionerPathologies && practitionerInfo.practitionerPathologies.length !== 0 && (
                                                                <div className="mt-4">
                                                                    <h6><i className="fa-solid fa-virus me-1"></i>Patologie
                                                                        trattate</h6>
                                                                    {practitionerInfo.practitionerPathologies.map((pathologyObj, index) => (
                                                                        <li key={index} className="mt-3 ms-2">
                                                                            {pathologyObj.pathology.name}<br/>
                                                                            <span
                                                                                className="ms-4">{pathologyObj.pathology.desription}</span>
                                                                        </li>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                                <div className="doc-information-details d-none" id="membership">
                                                    <div className="detail-title">
                                                        <h4>Membership</h4>
                                                    </div>
                                                    <div className="member-ship-info">
                  <span className="mem-check">
                    <i className="fa-solid fa-check"/>
                  </span>
                                                        <p>
                                                            Affiliate members include related allied health professionals-
                                                            evidence based (Dietitians, Physiotherapist, Occupational
                                                            therapist and Clinical Psychologist) who will team up with
                                                            allopathic physicians to support the Lifestyle Medicine movement
                                                            in India through ISLM.
                                                        </p>
                                                    </div>
                                                    <div className="member-ship-info mb-0">
                  <span className="mem-check">
                    <i className="fa-solid fa-check"/>
                  </span>
                                                        <p>
                                                            Physician members include the allopathic doctors only (MBBS, MD,
                                                            MS, DM, MCH, DNB or equivalent degree) who hold a valid medical
                                                            license as recognized by the Medical Council of India.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="doc-information-details d-none" id="awards">
                                                    <div
                                                        className="detail-title slider-nav d-flex justify-content-between align-items-center">
                                                        <h4>Awards</h4>
                                                        <div className="nav nav-container slide-3"/>
                                                    </div>
                                                    <OwlCarousel {...ouraward} className="awards-slider owl-carousel">
                                                        <div className="award-card">
                                                            <div className="award-card-info">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/bookmark-star.svg" alt="Img"/>
                      </span>
                                                                <h5>Award Name (2021)</h5>
                                                                <p>
                                                                    evidence based (Dietitians, Physiotherapist,
                                                                    Occupational
                                                                    therapist and Clinical)
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="award-card">
                                                            <div className="award-card-info">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/bookmark-star.svg" alt="Img"/>
                      </span>
                                                                <h5>Award Name (2022)</h5>
                                                                <p>
                                                                    evidence based (Dietitians, Physiotherapist,
                                                                    Occupational
                                                                    therapist and Clinical)
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="award-card">
                                                            <div className="award-card-info">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/bookmark-star.svg" alt="Img"/>
                      </span>
                                                                <h5>Award Name (2023)</h5>
                                                                <p>
                                                                    evidence based (Dietitians, Physiotherapist,
                                                                    Occupational
                                                                    therapist and Clinical)
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="award-card">
                                                            <div className="award-card-info">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/bookmark-star.svg" alt="Img"/>
                      </span>
                                                                <h5>Award Name (2024)</h5>
                                                                <p>
                                                                    evidence based (Dietitians, Physiotherapist,
                                                                    Occupational
                                                                    therapist and Clinical)
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="award-card">
                                                            <div className="award-card-info">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/bookmark-star.svg" alt="Img"/>
                      </span>
                                                                <h5>Award Name (2020)</h5>
                                                                <p>
                                                                    evidence based (Dietitians, Physiotherapist,
                                                                    Occupational
                                                                    therapist and Clinical)
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                                <div className="doc-information-details d-none" id="review">
                                                    <div className="detail-title">
                                                        <h4>Reviews (200)</h4>
                                                    </div>
                                                    <div className="doc-review-card">
                                                        <div className="user-info-review">
                                                            <div className="reviewer-img">
                                                                <a href="#" className="avatar-img">
                                                                </a>
                                                                <div className="review-star">
                                                                    <a href="#">kadajsalamander</a>
                                                                    <div className="rating">
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <span>5.0 | 2 days ago</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className="thumb-icon">
                      <i className="fa-regular fa-thumbs-up"/>
                      Yes,Recommend for Appointment
                    </span>
                                                        </div>
                                                        <p>
                                                            Thank you for this informative article! I've had a couple of
                                                            hit-and-miss experiences with freelancers in the past, and I
                                                            realize now that I wasn't vetting them properly. Your checklist
                                                            for choosing the right freelancer is going to be my go-to from
                                                            now on
                                                        </p>
                                                        <a href="#" className="reply d-flex align-items-center">
                                                            <i className="fa-solid fa-reply me-2"/>
                                                            Reply
                                                        </a>
                                                    </div>
                                                    <div className="doc-review-card">
                                                        <div className="user-info-review">
                                                            <div className="reviewer-img">
                                                                <a href="#" className="avatar-img">
                                                                </a>
                                                                <div className="review-star">
                                                                    <a href="#">Dane jose</a>
                                                                    <div className="rating">
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <span>5.0 | 1 Months ago</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className="thumb-icon">
                      <i className="fa-regular fa-thumbs-up"/>
                      Yes,Recommend for Appointment
                    </span>
                                                        </div>
                                                        <p>
                                                            As a freelancer myself, I find this article spot on! It's
                                                            important for clients to understand what to look for in a
                                                            freelancer and how to foster a good working relationship. The
                                                            point about mutual respect and clear communication is key in my
                                                            experience. Well done
                                                        </p>
                                                        <a href="#" className="reply d-flex align-items-center">
                                                            <i className="fa-solid fa-reply me-2"/>
                                                            Reply
                                                        </a>
                                                    </div>
                                                    <div className="doc-review-card mb-0">
                                                        <div className="user-info-review">
                                                            <div className="reviewer-img">
                                                                <a href="#" className="avatar-img">
                                                                </a>
                                                                <div className="review-star">
                                                                    <a href="#">Dane jose</a>
                                                                    <div className="rating">
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <i className="fas fa-star filled"/>
                                                                        <span>5.0 | 15 days ago</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className="thumb-icon">
                      <i className="fa-regular fa-thumbs-up"/>
                      Yes,Recommend for Appointment
                    </span>
                                                        </div>
                                                        <p>
                                                            Great article! I've bookmarked it for future reference. I'd love
                                                            to read more about managing long-term relationships with
                                                            freelancers, if you have any tips on that.
                                                        </p>
                                                        <a href="#" className="reply d-flex align-items-center">
                                                            <i className="fa-solid fa-reply me-2"/>
                                                            Reply
                                                        </a>
                                                        <div className="replied-info">
                                                            <div className="user-info-review">
                                                                <div className="reviewer-img">
                                                                    <a href="#" className="avatar-img">
                                                                    </a>
                                                                    <div className="review-star">
                                                                        <a href="#">Robert Hollenbeck</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p>
                                                                Thank you for your comment and I will try to make a another
                                                                post on that topic.
                                                            </p>
                                                            <a href="#" className="reply d-flex align-items-center">
                                                                <i className="fa-solid fa-reply me-2"/>
                                                                Reply
                                                            </a>
                                                        </div>
                                                        {/* Pagination */}
                                                        <div className="pagination dashboard-pagination">
                                                            <ul>
                                                                <li>
                                                                    <a href="#" className="page-link prev-link">
                                                                        <i className="fa-solid fa-chevron-left me-2"/>
                                                                        Prev
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#" className="page-link active">
                                                                        1
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#" className="page-link">
                                                                        2
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#" className="page-link">
                                                                        3
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#" className="page-link">
                                                                        4
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#" className="page-link">
                                                                        5
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#" className="page-link">
                                                                        6
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#" className="page-link next-link">
                                                                        Next
                                                                        <i className="fa-solid fa-chevron-right ms-2"/>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {/* /Pagination */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5">
                                    <div className="container d-none d-md-block" id="appointment">
                                        <div className="card doc-profile-card">
                                            <div className="card-header color-header">
                                                <h4>Prenota una visita</h4>
                                            </div>
                                            <div className="card-body">
                                                <div
                                                    className="doctor-widget doctor-profile-two baseline-important row px-2">
                                                    <div className="col-12 doctor-form px-2">
                                                        <p>Seleziona le opzioni per visualizzare le disponibilità</p>
                                                    </div>
                                                    <div className="col-12 mb-2 doctor-form px-2">
                                                        <form action="#" className="doctor-search">
                                                            <div className="input-box-twelve row">
                                                                <div className="col-12 search-input input-block">
                                                                    <Dropdown
                                                                        value={selectedValueSpecialization}
                                                                        onChange={handleSpecializationChange}
                                                                        options={selectValueSpecialization}
                                                                        optionLabel="specialization.description"
                                                                        optionValue="specialization.id"
                                                                        placeholder="Seleziona specializzazione"
                                                                        className="custom-prime-select"
                                                                        panelClassName="custom-dropdown"
                                                                        appendTo="self"
                                                                    />
    
                                                                    <Link className="current-loc-icon current_location"
                                                                          to="#"></Link>
                                                                </div>
    
                                                                <div className="col-12 search-input input-block">
                                                                    <Dropdown
                                                                        value={selectedValueOffice}
                                                                        onChange={handleOfficeChange}
                                                                        options={selectValueOffice}
                                                                        optionLabel="name"
                                                                        placeholder="Seleziona ambulatorio"
                                                                        className="custom-prime-select"
                                                                        panelClassName="custom-dropdown"
                                                                        appendTo="self"
                                                                    />
                                                                    <Link className="current-loc-icon current_location"
                                                                          to="#">
                                                                    </Link>
                                                                </div>
    
                                                                <div className="col-12 mb-2 search-input input-block">
                                                                    <Dropdown
                                                                        value={selectedValueTypeAppointment}
                                                                        onChange={handleTypeAppointmentChange}
                                                                        options={selectValueTypeAppointment}
                                                                        optionLabel="description"
                                                                        placeholder="Tipologia di appuntamento"
                                                                        className="custom-prime-select"
                                                                        panelClassName="custom-dropdown"
                                                                        appendTo="self"
                                                                    />
                                                                    <Link className="current-loc-icon current_location"
                                                                          to="#">
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    {(!selectValueTypeAppointment || selectValueTypeAppointment.length === 0 ||
                                                        !selectValueOffice || selectValueOffice.length === 0 ||
                                                        !selectValueSpecialization || selectValueSpecialization.length === 0)
                                                        ? <div id="error-message" className="alert alert-danger mt-3" style={{display: 'block'}} role="alert">
                                                            Non accetta prenotazioni online su 3SMB
                                                            </div>
                                                        : ""
                                                        }
                                                </div>
                                                <div>
                                                    {alerts.map((alert, index) => (
                                                        <div
                                                            key={index}
                                                            id={`alert-${alert.alertId}`}
                                                            className="alert alert-success mt-2"
                                                            role="alert"
                                                            style={{
                                                                backgroundColor: alert.backgroundColor,
                                                                color: alert.textColor,
                                                                borderColor: alert.borderColor,
                                                                padding: '10px 10px 0px 10px'
                                                            }}
                                                        >
                                                            <h6>{alert.title}</h6>
                                                            <p>{alert.body}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={`vertical-layout option-border ${isVisible ? '' : 'd-none'}`}>
                                                    <div className="booking-schedule schedule-widget ps-0">
                                                        <div className="schedule-header">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="day-slot vertical-layout">
                                                                        {availability && availability.length > 0 ? (
                                                                            <div>
                                                                                <div className="row">
                                                                                    <div className="col-9">
                                                                                        Seleziona e prenota il tuo appuntamento:
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-3 buttons-container">
                                                                                        <button
                                                                                            className="button-option-availability"
                                                                                            onClick={goToPreviousWeek}>{"<"}</button>
                                                                                        <button
                                                                                            className="button-option-availability"
                                                                                            onClick={goToNextWeek}>{">"}</button>
                                                                                    </div>
                                                                                </div>
                                                                                <ul>
                                                                                    {availability.map((item, index) => (
                                                                                        <li key={index}>
                                                                                          <span
                                                                                              style={{fontSize: '10px'}}>
                                                                                                {['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'][item.dayOfWeek - 1]}
                                                                                          </span>
                                                                                            <span className="slot-date">
                                                                                          {new Date(item.day).toLocaleDateString('it-IT', {
                                                                                              day: 'numeric',
                                                                                              month: 'short'
                                                                                          })}
                                                                                        </span>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        ) : (
                                                                            <p>Nessuna disponibilità</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="schedule-cont">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="time-slot">
                                                                        <ul className="clearfix" id="clearfix"
                                                                            ref={listRef}>
                                                                            {availability && availability
                                                                                .map((item, index) => {
                                                                                    const itemDate = new Date(item.day);
                                                                                    const todayDate = new Date();

                                                                                    itemDate.setHours(0, 0, 0, 0);
                                                                                    todayDate.setHours(0, 0, 0, 0);
                                                                                    
                                                                                    return (
                                                                                    <li key={index}
                                                                                        style={{marginBottom: '10px'}}>
                                                                                        {item.slotsByOffice && itemDate >= todayDate && item.slotsByOffice.length > 0 ? (
                                                                                            item.slotsByOffice.flatMap((office, officeIndex) => {
                                                                                                let hasAvailableSlots = false;
                                                                                                const slots = office.blocks.flatMap((block, blockIndex) => {
                                                                                                    if (block && block.scheduleAvailability && block.scheduleAvailability.scheduleAppointmentCategory && block.scheduleAvailability.scheduleAppointmentCategory.patientVisible) {
                                                                                                        const freeTimes = block.freeTimes;
                                                                                                        let subSlots = [];
    
                                                                                                        if (freeTimes) {
                                                                                                            freeTimes.forEach(freeTime => {
                                                                                                                const generatedSlots = generateSubSlots(
                                                                                                                    freeTime.startTime,
                                                                                                                    freeTime.endTime,
                                                                                                                    itemDate
                                                                                                                );
                                                                                                                subSlots = subSlots.concat(generatedSlots);
                                                                                                            });
                                                                                                        }
    
                                                                                                        return subSlots.map((slot, slotIndex) => (
                                                                                                            <div
                                                                                                                className="slot-option"
                                                                                                                key={`${officeIndex}-${blockIndex}-${slotIndex}`}>
                                                                                                                <a href={`/checkout/${slot.start}/${slot.end}/${item.day}/${doctor.practitionerId}/${specializationId}/${selectedValueOffice.id}/${selectedValueTypeAppointment.scheduleAppointmentTypeId}`}>
                                                                                                                    <span>{slot.start}</span>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        ));
                                                                                                    } else if (block && !block.scheduleAvailability) {
                                                                                                        hasAvailableSlots = true;
                                                                                                    }
                                                                                                    return [];
                                                                                                });
    
                                                                                                return (
                                                                                                    <React.Fragment
                                                                                                        key={officeIndex}>
                                                                                                        {slots}
                                                                                                        {!hasAvailableSlots &&
                                                                                                            <p className="no-availability">-</p>}
                                                                                                    </React.Fragment>
                                                                                                );
                                                                                            })
                                                                                        ) : (
                                                                                            <p className="no-availability">-</p>
                                                                                        )}
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            width: '100%'
                                                                        }}>
                                                                            <button
                                                                                onClick={toggleContent}
                                                                                id="button-show"
                                                                                style={{
                                                                                    marginTop: '10px',
                                                                                    border: 'none',
                                                                                    padding: '5px 10px',
                                                                                    borderRadius: '5px',
                                                                                    cursor: 'pointer',
                                                                                    display: 'inline-block',
                                                                                }}>
                                                                                {isExpanded ? (
                                                                                    <>
                                                                                        Mostra Meno <i
                                                                                        className="fas fa-chevron-up"
                                                                                        style={{marginLeft: '10px'}}></i>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        Mostra Tutti <i
                                                                                        className="fas fa-chevron-down"
                                                                                        style={{marginLeft: '10px'}}></i>
                                                                                    </>
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Page Content */}
                </>
                <div>
                    {loading ? <img src="/assets/img/logo-Sfondo-trasparente-Segreteria-medica-3SMB-1.png" alt="Logo" className="loader" /> : ''}
                    {loading && <div className="overlay"></div>}
                </div>
                <Footer {...props} />
            </div>
        </>
    )
}

export default DoctorProfileTwo