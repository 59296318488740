import React from "react";
import Footer from "../../footer";
import Header from "../../home/home/header";

const Policy = (props) => {
  return (
    <>
      <Header {...props} />
      <>
        {/* /Breadcrumb */}
        {/* Privacy Policy */}
        <section className="terms-section">
          <div className="container">
            <div className="row custom-header-policy-section1 ms-2 me-2 mb-5">
              <div className="col-0 col-lg-1"></div>
              <div className="col-12 col-lg-10">
                <h4 className="mt-5"><b>Informativa sulla cookie policy</b></h4>
                <h5 className="mt-5"><b>Cookie Policy</b></h5>
                <p className="mt-5 p-policy-font">Ultimo aggiornamento: 2024-10-09<br/><br/></p>
                <p className="mt-5 p-policy-font">
                  <b>1. Utilizzo dei Cookie</b>
                  <br/><br/>
                  Utilizziamo cookie e tecnologie simili sul nostro sito web per migliorare l'esperienza degli utenti,
                  analizzare l'utilizzo del sito e fornire pubblicità mirate. I cookie possono essere temporanei
                  (cookie di sessione) o permanenti (cookie persistenti) e possono essere utilizzati per diversi
                  scopi, tra cui:
                  <br/><br/>
                  Assicurare il corretto funzionamento del sito.<br/>
                  Memorizzare le preferenze degli utenti.<br/>
                  Analizzare l'utilizzo del sito per migliorare le funzionalità e le prestazioni.<br/>
                  Fornire contenuti e pubblicità mirati.
                  <br/><br/>
                  <b>2. Come utilizziamo i cookie?</b>
                  <br/><br/>
                  Come la maggior parte dei servizi online, il nostro sito web utilizza cookie di prima parte e di
                  terze parti per diversi scopi. I cookie di prima parte sono per lo più necessari per il corretto
                  funzionamento del sito web e non raccolgono alcun dato personale. I cookie di terze parti utilizzati
                  sul nostro sito web servono principalmente a capire come funziona il sito web, come interagite con
                  il nostro sito web, a mantenere sicuri i nostri servizi, a fornire pubblicità rilevanti per voi e,
                  in definitiva, a fornirvi un'esperienza utente migliore e migliorata e a velocizzare le vostre
                  future interazioni con il nostro sito web.
                  <br/><br/>
                  <div className="cky-audit-table-element"></div>
                  <b>3. Gestione dei Cookie</b>
                  <br/><br/>
                  Potete modificare le vostre preferenze sui cookie in qualsiasi momento cliccando sul pulsante qui
                  sopra. In questo modo, è possibile visualizzare il banner di consenso ai cookie e modificare le
                  proprie preferenze o ritirare il proprio consenso immediatamente. Inoltre, i diversi browser offrono
                  metodi diversi per bloccare e cancellare i cookie utilizzati dai siti web. È possibile modificare le
                  impostazioni del browser per bloccare/cancellare i cookie. Di seguito sono elencati i link ai
                  documenti di supporto su come gestire ed eliminare i cookie dai principali browser web.
                  <br/><br/>
                  Chrome: https://support.google.com/accounts/answer/32050
                  <br/><br/>
                  Safari: https://support.apple.com/en-in/guide/safari/sfri11471/mac
                  <br/><br/>
                  Firefox:
                  https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US
                  <br/><br/>
                  Internet Explore:
                  https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc
                  <br/><br/>
                  <b>4. Consenso</b>
                  <br/><br/>
                  Continuando a utilizzare il nostro sito web, gli utenti acconsentono all'uso dei cookie in
                  conformità con la presente Cookie Policy e la nostra Informativa sulla Privacy.
                  <br/><br/>
                  <b>5. Contatti</b>
                  <br/><br/>
                  Per domande, preoccupazioni o richieste in merito alla cookie policy, gli Utenti possono contattarci ai seguenti recapiti:
                  <br/><br/>
                  Segreteria medica 3smb S.r.l.
                  <br/>
                  Via Ilaria Alpi 21, Scarperia e S. Piero a Sieve, 50038 (FI)
                  <br/>
                  <a href="mailto:info@3smb.it">info@3smb.it</a><br/>
                  <a href="tel:+390550540921">+39 0550540921</a><br/>
                </p>
            </div>
            <div className="col-0 col-lg-1"></div>
          </div>
        </div>
      </section>
      {/* /Privacy Policy */}
    </>

  <Footer {...props} />
</>
)
  ;
};

export default Policy;
