import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import LoginContainer from "./client/components/login/login.jsx";
import Components from "./client/components/pages/component";
import Checkout from "./client/components/patients/checkout";
import PatientAccounts from "./client/components/patients/accounts";
import Terms from "./client/components/pages/terms";
import Policy from "./client/components/pages/policy";
import Error500 from "./client/components/pages/error/Error500.jsx";
import Faq from "./client/components/pages/faq/index.jsx";
import SearchDoctor2 from "./client/components/pages/searchdoctor/search-doctor2.jsx";
import { base_path } from "./environment.jsx";
import Home from "./client/components/home/home/index.jsx";
import Iframe from "./client/components/home/home/iframe.jsx";
import IframeDoctor from "./client/components/home/home/iframe-doctor.jsx";
import DoctorProfileTwo from "./client/components/patients/doctorprofile2/index.jsx";
const AppContainer = function (props) {
  if (props) {
    const url = props.location.pathname.split("/")[1];

    useEffect(() => {
      const handleMouseMove = (event) => {
        const cursorInner = document.querySelector(".cursor-inner");
        const cursorOuter = document.querySelector(".cursor-outer");

        if (cursorOuter) {
          cursorOuter.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
        }

        if (cursorInner) {
          cursorInner.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
        }
      };

      const handleMouseEnter = () => {
        const cursorInner = document.querySelector(".cursor-inner");
        const cursorOuter = document.querySelector(".cursor-outer");

        if (cursorInner) {
          cursorInner.classList.add("s");
        }

        if (cursorOuter) {
          cursorOuter.classList.add("s");
        }
      };

      const handleMouseLeave = (event) => {
        const cursorInner = document.querySelector(".cursor-inner");
        const cursorOuter = document.querySelector(".cursor-outer");

        if (
          event.target.tagName !== "A" ||
          !event.target.closest(".cursor-pointer")
        ) {
          if (cursorInner) {
            cursorInner.classList.remove("cursor-hover");
          }

          if (cursorOuter) {
            cursorOuter.classList.remove("cursor-hover");
          }
        }
      };

      document.body.addEventListener("mousemove", handleMouseMove);
      document.body.addEventListener("mouseenter", handleMouseEnter);
      document.body.addEventListener("mouseleave", handleMouseLeave);

      const cursorInner = document.querySelector(".cursor-inner");
      const cursorOuter = document.querySelector(".cursor-outer");

      if (cursorInner) {
        cursorInner.style.visibility = "visible";
      }

      if (cursorOuter) {
        cursorOuter.style.visibility = "visible";
      }

      return () => {
        document.body.removeEventListener("mousemove", handleMouseMove);
        document.body.removeEventListener("mouseenter", handleMouseEnter);
        document.body.removeEventListener("mouseleave", handleMouseLeave);
      };
    }, []);

    return (
      <Router basename={base_path}>
        {" "}
        {url === "admin" ? (
          <div>
           
          </div>
        ) : url === "pharmacyadmin" ? (
          <div>
          
          </div>
        ) : (
          <div>
            <Switch>
              <Route path="/login" exact component={LoginContainer} />
              <Route path="/lista-dottori/:Sspe/:Spid/:pid/:spe" exact component={SearchDoctor2}/>
              <Route path="/pages/component" exact component={Components} />
              <Route path="/pages/error-500" exact component={Error500} />
              <Route path="/pages/faq" exact component={Faq} />
              <Route path="/profilo-dottore/:doctorName/:specialization/:docId/:speId/:sc" exact component={DoctorProfileTwo}/>
              <Route path="/checkout/:Sl/:Sle/:D/:Di/:Si/:U/:Ty" exact component={Checkout} />
              <Route
                path="/patient/accounts"
                exact
                component={PatientAccounts}
              />
              <Route path="/pages/terms" exact component={Terms} />
              <Route path="/cookie-policy" exact component={Policy} />

               {/* Home routes */}
               <Route path="/" exact component={Home} />
               <Route path="/iframe" exact component={Iframe} />
               <Route path="/iframe-doctor" exact component={IframeDoctor} />
            </Switch>
          </div>
        )}
      </Router>
    );
  }
  return null;
};

export default AppContainer;
