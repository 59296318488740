import React, { useState, useEffect, useRef } from 'react';
import config from "../../../../../config";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import {
  FiCalendar,
  FiMapPin,
} from "react-icons/fi";
import {Link, useParams} from "react-router-dom";
import Select from "react-select";
import {Helmet} from "react-helmet";
import {Dropdown} from "primereact/dropdown";

const mapStyles = {
  width: '100%',
  height: '400px',
  borderRadius: '15px',
  overflow: 'hidden',
};

const getCoordinatesFromAddress = (address, google) => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const { location } = results[0].geometry;
        resolve({
          lat: location.lat(),
          lng: location.lng(),
        });
      } else {
        reject('Geocoding failed: ' + status);
      }
    });
  });
};

const Doctors = () => {
  const options = [
    {value: 'AtoZ', label: "A alla Z"},
    {value: 'ZtoA', label: "Z alla A"},
  ];

  const {pid, spe } = useParams();

  const [doctors, setDoctors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [doctorsPerPage] = useState(10);
  const [totalDoctor, setTotalDoctor] = useState([]);
  const [Spe, setSpeciality] = useState('');
  const [Pid, setProvince] = useState('');
  const [sortOption, setSortOption] = useState(options[0]);
  const [markers, setMarkers] = useState([]);
  const [geocodingDone, setGeocodingDone] = useState(false); // Stato per tenere traccia se la geocoding è stata eseguita
  const googleRef = useRef(null); // Riferimento per memorizzare l'istanza di Google Maps
  const [initialCenter, setInitialCenter] = useState({ lat: 41.9028, lng: 12.4964 }); // Coordinate iniziali di Roma
  const [mapLoaded, setMapLoaded] = useState(false); // Stato per controllare se la mappa è pronta per essere visualizzata
  
  const [expandedDoctor, setExpandedDoctor] = useState(null);
  const [popupData, setPopupData] = useState(null);

  const [loading, setLoading] = useState(true);

  const listRefs = useRef({});
  const buttonRefs = useRef({});

  const toggleContent = (doctorId) => {
    setExpandedDoctor((prevDoctorId) => (prevDoctorId === doctorId ? null : doctorId));
  };

  const handleOfficeChange = (doctorId, officeId) => {
    setDoctors((prevDoctors) =>
        prevDoctors.map((doc) =>
            doc.practitionerSpecializationId === doctorId
                ? {
                  ...doc,
                  selectedOffice: doc.office.find((office) => office.data.id === officeId) || null,
                }
                : doc
        )
    );
  };

  const handleAppointmentTypeChange = (doctorId, scheduleAppointmentTypeId) => {
    setDoctors((prevDoctors) =>
        prevDoctors.map((doc) =>
            doc.practitionerSpecializationId === doctorId
                ? {
                  ...doc,
                  selectedAppointmentType: doc.appointmentTypes.find(
                      (type) => type.scheduleAppointmentTypeId === scheduleAppointmentTypeId
                  ) || null,
                }
                : doc
        )
    );
  };

  const updateAvailability = async () => {
    updateDoctorAvailabilityForAll();
  };

  useEffect(() => {
    let title = '3SMB - Elenco dottori';
    if (Spe || Pid) {
      title += ' ';
      if (Spe) title += Spe;
      if (Spe && Pid) title += ', ';
      if (Pid) title += Pid;
    }

    document.title = title;

    const titleMetaTag = document.querySelector('meta[name="title"]');
    const descriptionMetaTag = document.querySelector('meta[name="description"]');

    if (titleMetaTag) titleMetaTag.setAttribute('content', title);

    let description = `Scopri i nostri dottori disponibili per: `;
    if (Spe || Pid) {
      description += ' ';
      if (Spe) description += `${Spe}.`;
      if (Spe && Pid) description += ', ';
      if (Pid) description += `${Pid}.`;
    }
    if (descriptionMetaTag) descriptionMetaTag.setAttribute('content', description);

    const canonicalUrl = `https://3smb.cercadott.it/lista-dottori/${Spe || 'und'}/${Pid || 'und'}/${pid}/${spe}`;

    const linkCanonicalTag = document.querySelector('link[rel="canonical"]');
    if (linkCanonicalTag) {
      linkCanonicalTag.setAttribute('href', canonicalUrl);
    } else {
      const newCanonicalTag = document.createElement('link');
      newCanonicalTag.setAttribute('rel', 'canonical');
      newCanonicalTag.setAttribute('href', canonicalUrl);
      document.head.appendChild(newCanonicalTag);
    }
  }, [Spe, Pid]);

  useEffect(() => {
    const checkHeight = (doctorId) => {
      const listRef = listRefs.current[doctorId];
      const buttonRef = buttonRefs.current[doctorId];
      if (listRef && buttonRef) {
        const originalMaxHeight = listRef.style.maxHeight;
        listRef.style.maxHeight = 'none';
        const listHeight = listRef.scrollHeight;
        listRef.style.maxHeight = originalMaxHeight;

        if (listHeight > 270) {
          buttonRef.style.display = 'block';
        } else {
          buttonRef.style.display = 'none';
        }
      }
    };

    doctors.forEach((doctor) => checkHeight(doctor.practitioner.id));

  }, [doctors, expandedDoctor]);
  
  const MapContainer = (props) => {

    if (doctors.length > 0 && !geocodingDone) {

      const { google } = props;
      googleRef.current = google;
      setGeocodingDone(true);
      const fetchCoordinates = async () => {
        const newMarkers = [];
        for (const doctor of doctors) {
          const office = doctor.selectedOffice;
          const address = office && office.data
              ? `${office.data?.address}, ${office.data?.postalCode}, ${office.data?.province?.description}`
              : null;

          if (address) {
            try {
              const { lat, lng } = await getCoordinatesFromAddress(address, googleRef.current);
              newMarkers.push({
                id: doctor.practitioner.id,
                name: doctor.practitioner.firstName,
                surname: doctor.practitioner.lastName,
                position: { lat, lng },
                profileLink: `/profilo-dottore/${encodeURIComponent((doctor?.practitioner?.lastName || '').replace(/\s+/g, '-'))}-${encodeURIComponent((doctor?.practitioner?.firstName || '').replace(/\s+/g, '-'))}/${encodeURIComponent((doctor?.specialization?.description || '').replace(/\s+/g, '-'))}/${doctor?.practitioner?.id || ''}/${doctor?.practitionerSpecializationId || ''}/${doctor?.specialization?.id || ''}`,
              });
            } catch (error) {
              console.error('Error fetching coordinates for address:', address, error);
            }
          } else {
            console.warn('Doctor office data is missing or incomplete for doctor:', doctor);
          }
        }

        setMarkers(newMarkers);

        if (doctors.length > 0) {
          const firstDoctor = doctors[0];
          const address = `${firstDoctor.selectedOffice?.data?.address}, ${firstDoctor.selectedOffice?.data?.postalCode}, ${firstDoctor.selectedOffice?.data?.province?.description}`;
          try {
            const { lat, lng } = await getCoordinatesFromAddress(address, googleRef.current);
            setInitialCenter({ lat, lng });
          } catch (error) {
            console.error('Error fetching coordinates for initial center address:', address, error);
          }
        }
      };

      fetchCoordinates();
    }

    return (
        <Map
            google={google}
            zoom={7} // Ridotto livello di zoom iniziale
            style={mapStyles}
            initialCenter={initialCenter} // Usa le coordinate iniziali
        >
          {markers.map((marker) => (
              <Marker
                  key={marker.id}
                  position={marker.position}
                  title={'Dr. ' + marker.surname + ' ' + marker.name}
                  onClick={() => window.location.href = marker.profileLink}
              />
          ))}
        </Map>
    );
  };

  const WrappedMapContainer = GoogleApiWrapper({
    apiKey: config.apiKeyMaps,
  })(MapContainer);
  
  async function updateDate(specializationId, officeId, todayCount) {
    try {
      const formattedData = await getPractitionerApointmentsByInterval(specializationId, officeId, todayCount);
      return formattedData.data;
    } catch (error) {
      console.error('Errore durante il recupero delle disponibilità:', error);
      return [];
    }
  }

  function toTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  
  const timeToDate = (timeStr, referenceDate) => {
    if (!timeStr) return new Date(referenceDate);
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    const date = new Date(referenceDate);
    date.setHours(hours, minutes, seconds, 0);
    return date;
  };

  const formatTime24 = (date) => {
    if (!(date instanceof Date)) {
      throw new Error('Input is not a Date object');
    }
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const generateSubSlots = (startTimeStr, endTimeStr, referenceDate, appointmentType) => {
    const startTime = timeToDate(startTimeStr, referenceDate);
    const endTime = timeToDate(endTimeStr, referenceDate);
    const subSlots = [];
    let currentStartTime = startTime;
    const durationMillis = appointmentType?.duration * 60000;

    while (currentStartTime < endTime) {
      const currentEndTime = new Date(currentStartTime.getTime() + durationMillis);
      if (currentEndTime > endTime) {
        break;
      }
      subSlots.push({
        start: formatTime24(currentStartTime),
        end: formatTime24(currentEndTime)
      });
      currentStartTime = currentEndTime;
    }

    return subSlots;
  };

  const updateWeek = async (doctorIndex, increment) => {
    setLoading(true);
    const newDate = new Date(doctors[doctorIndex].todayCount);
    newDate.setDate(newDate.getDate() + increment);

    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate()); // Inizio della settimana corrente

    if (increment < 0 && newDate <= startOfWeek) {
      setLoading(false);
      return; // Esce dalla funzione se la data è precedente all'inizio della settimana corrente
    }

    setDoctors((prevDoctors) => {
      const updatedDoctors = [...prevDoctors];
      updatedDoctors[doctorIndex].todayCount = newDate;
      return updatedDoctors;
    });
    setLoading(false);

    try {
      const updatedDoctors = [...doctors];
      const doctor = updatedDoctors[doctorIndex];
      const newAvailability = await getPractitionerApointmentsByInterval(
          doctor.practitionerSpecializationId,
          doctor.selectedOffice.data.id,
          newDate // Passa la nuova data aggiornata
      );
      setDoctors((prevDoctors) => {
        const updatedDoctors = [...prevDoctors];
        updatedDoctors[doctorIndex].availability = newAvailability.data;
        setLoading(false);
        return updatedDoctors;
      });
    } catch (error) {
      setLoading(false);
      console.error('Errore durante l\'aggiornamento delle disponibilità:', error);
    }
  };

  const goToNextWeek = (doctorIndex) => {
    if(window.innerWidth < 768) {
      updateWeek(doctorIndex, 3);
    }else{
      updateWeek(doctorIndex, 6);
    }
  };

  const goToPreviousWeek = (doctorIndex) => {
    if(window.innerWidth < 768) {
      updateWeek(doctorIndex, -3);
    }else{
      updateWeek(doctorIndex, -6);
    }
  };

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        setLoading(true);
        
        const speTmp = spe;
        const pidTmp = pid;

        const doctorsData = await getDoctor(speTmp, pidTmp, currentPage, doctorsPerPage, sortOption);
        setDoctors(doctorsData);

        window.scrollTo(0, 0);
        
        if (pidTmp !== "undefined" || speTmp !== "undefined" || pidTmp !== "und" || speTmp !== "und") {
        const doctorsWithOffices = await Promise.all(doctorsData.map(async (doctor) => {
          try {
            const offices = await getListOffice(doctor.practitionerSpecializationId);
            const validOffices = [];

            for (const office of offices) {
              try {
                const officeDetails = await getOfficeInfo(doctor.practitionerSpecializationId, office.id);
                if (officeDetails.data.services && Array.isArray(officeDetails.data.services) &&
                    officeDetails.data.services.some(service => service.id === config.codeOffice)) {
                  validOffices.push(officeDetails);
                }
              } catch (error) {
                console.error('Errore durante il recupero delle informazioni dell\'ambulatorio:', error);
              }
            }

            const selectedOffice = validOffices.length > 0 ? validOffices[0] : null;

            let appointmentTypes = [];
            try {
              const formattedData = await getPractitionerAppointmentTypesList(doctor.practitionerSpecializationId);
              appointmentTypes = formattedData.data.filter(item => item.patientVisible);
            } catch (error) {
              console.error('Errore durante il recupero dei tipi di appuntamento:', error);
            }
            
            return {
              ...doctor,
              office: validOffices,
              selectedOffice: selectedOffice,
              selectedAppointmentType: null,
              appointmentTypes: appointmentTypes
            };

          } catch (error) {
            console.error('Errore durante il recupero degli uffici:', error);
            return { ...doctor, office: [], selectedOffice: null, selectedAppointmentType: null }; // Gestisci l'errore
          }
        }));

        setDoctors(doctorsWithOffices);

        if (doctorsWithOffices.length > 0 && pidTmp !== "undefined" && pidTmp !== "und") {
          setProvince(doctorsWithOffices[0].practitioner.addressMunicipality.province);
        }

        if (doctorsWithOffices.length > 0 && speTmp !== "undefined" && speTmp !== "und") {
          setSpeciality(doctorsWithOffices[0].specialization.description);
        }

        setMapLoaded(true);
        setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error('Errore durante il recupero dei dati:', error);
      }
    };

    fetchDoctors();
  }, [currentPage, sortOption]);

  
  const updateDoctorAvailabilityForAll = async () => {
    setLoading(true);
    for (const doctor of doctors) {
      const { selectedOffice, selectedAppointmentType } = doctor;

      if (selectedOffice && selectedAppointmentType) {
        try {
          const todayCount = new Date();
          todayCount.setDate(todayCount.getDate() + 1);
          let availability = [];

          availability = await updateDate(
              doctor.practitionerSpecializationId,
              selectedOffice.data.id,
              todayCount
          );

          setDoctors((prevDoctors) =>
              prevDoctors.map((doc) =>
                  doc.practitionerSpecializationId === doctor.practitionerSpecializationId
                      ? {
                        ...doc,
                        availability: availability,
                        todayCount: todayCount,
                      }
                      : doc
              )
          );
          Alert(doctor);
        } catch (error) {
          setLoading(false);
          console.error("Errore durante il recupero delle disponibilità:", error);
        }
      }
    }
    setLoading(false);
  };

  const getDoctor = async (speciality, province, currentPage, doctorsPerPage, sortOption) => {
    let url = `${config.apiUrl}/SMB/PractitionersList`;

    let params = [];

    if (province !== undefined && province !== "undefined" && province !== "und") {
      params.push(`ProvinceId=${encodeURIComponent(province)}`);
    }

    if (speciality !== undefined && speciality !== "undefined" && speciality !== "und") {
      params.push(`SpecializationId=${encodeURIComponent(speciality)}`);
    }

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }
    
    const response = await fetch(url);
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const responseData = await response.json();
      
      let doctors = responseData.data;

      if (sortOption.value === 'AtoZ') {
        doctors.sort((a, b) => a.practitioner.lastName.localeCompare(b.practitioner.lastName));
      } else if (sortOption.value === 'ZtoA') {
        doctors.sort((a, b) => b.practitioner.lastName.localeCompare(a.practitioner.lastName));
      }

      const seenIds = new Set();
      const uniqueDoctors = doctors.filter(doc => {
        if (seenIds.has(doc.practitioner.id)) {
          return false;
        } else {
          seenIds.add(doc.practitioner.id);
          return true;
        }
      });

      setTotalDoctor(uniqueDoctors.length);
      
      const indexOfLastDoctor = currentPage * doctorsPerPage;
      const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;

      // Restituisci solo i dottori per la pagina corrente
      return uniqueDoctors.slice(indexOfFirstDoctor, indexOfLastDoctor);
    } else {
      setLoading(false);
      throw new Error("Response is not JSON");
    }
  };

  const handleSortChange = (selectedOption) => {
    setSortOption(selectedOption);
  };

  const getListOffice = async (specializationId) => {
    try {
      const url = `${config.apiUrl}/SMB/PractitionerOfficeList?PractitionerSpecializationId=${specializationId}`;
      const response = await fetch(url);
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        return data.data;
      } else {
        throw new Error("Response is not JSON");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  const getOfficeInfo = async (practitionerSpecializationId, officeId) => {
    try {
      const url = `${config.apiUrl}/SMB/OfficeInfo?PractitionerSpecializationId=${practitionerSpecializationId}&OfficeId=${officeId}`;
      const response = await fetch(url);
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Response is not JSON");
      }
    } catch (error) {
      console.error('Error fetching office info:', error);
      throw error;
    }
  };

  const getPractitionerApointmentsByInterval = async (practitionerSpecializationId, office, todayCount) => {
    try {

      const startOfWeek = new Date(todayCount);
      startOfWeek.setDate(todayCount.getDate() - 1);
      const endOfWeek = new Date(startOfWeek);
      if(window.innerWidth < 768) {
        endOfWeek.setDate(endOfWeek.getDate() + 2);
      }else {
        endOfWeek.setDate(endOfWeek.getDate() + 5);
      }

      const startDateFormatted = startOfWeek.toISOString().split('T')[0]; // Ottiene YYYY-MM-DD
      const endDateFormatted = endOfWeek.toISOString().split('T')[0]; // Ottiene YYYY-MM-DD

      const url = `${config.apiUrl}/SMB/PractitionerApointmentsByInterval?PractitionerSpecializationId=${practitionerSpecializationId}&StartDate=${startDateFormatted}&EndDate=${endDateFormatted}&OfficeId=${office}`;

      const response = await fetch(url);
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Response is not JSON");
      }
    } catch (error) {
      console.error('Error fetching office info:', error);
      throw error;
    }
  };

  const getPractitionerAppointmentTypesList = async (practitionerSpecializationId) => {
    try {
      const url = `${config.apiUrl}/SMB/PractitionerAppointmentTypesList?PractitionerSpecializationId=${practitionerSpecializationId}`;
      const response = await fetch(url);
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Response is not JSON");
      }
    } catch (error) {
      console.error('Error fetching office info:', error);
      throw error;
    }
  };

  function Alert(doctor) {
    getAlert("BANNER", doctor.practitionerSpecializationId, doctor.selectedAppointmentType.scheduleAppointmentTypeId)
        .then((data) => {
          setDoctors((prevDoctors) =>
              prevDoctors.map((doc) =>
                  doc.practitionerSpecializationId === doctor.practitionerSpecializationId
                      ? {
                        ...doc,
                        alerts: data.data,
                      }
                      : doc
              )
          );
        })
        .catch((error) => {
          console.error('Errore durante il recupero dei dati:', error);
        });

    getAlert("MODAL", doctor.practitionerSpecializationId, doctor.selectedAppointmentType.scheduleAppointmentTypeId)
        .then((data) => {
          if (Array.isArray(data.data) && data.data.length > 0) {
             setPopupData(data.data[0]);
          }else{
            setPopupData(null);
          }
        })
        .catch((error) => {
          console.error('Errore durante il recupero dei dati:', error);
        });
  }

  const getAlert = (alertType, practitionerSpecializationId, scheduleAppointmentTypeId) => {
    const url = `${config.apiUrl}/SMB/GetAlert?alertType=${alertType}&practitionerSpecilizationId=${practitionerSpecializationId}&appointmentTypeId=${scheduleAppointmentTypeId}`;
    return fetch(url)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Response is not JSON");
          }
        })
        .then((responseData) => {
          return responseData;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          throw error;
        });
  };

  const AlertPopup = ({ title, body, backgroundColor, textColor, onClose }) => {
    const handleOverlayClick = (e) => {
      if (e.target.classList.contains('modal') || e.target.classList.contains('modal-backdrop')) {
        onClose();
      }
    };

    return (
        <div
            className="modal fade show"
            tabIndex="-1"
            role="dialog"
            style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} // Overlay scuro
            onClick={handleOverlayClick}
        >
          <div
              className="modal-dialog"
              role="document"
              style={{ marginTop: '15vh' }} // Spazio sopra il popup
          >
            <div className="modal-content" style={{ backgroundColor, color: textColor }}>
              <div className="modal-header" style={{ borderBottom: 'none' }}>
                <h5 className="modal-title">{title}</h5>
                <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={onClose}
                    style={{ border: 'none', background: 'transparent', fontSize: '1.5rem', color: textColor }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>{body}</p>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const handleClosePopup = () => {
    setPopupData(null);
  };

  return (
      <div className="row">
        {popupData && (
            <AlertPopup
                title={popupData.title}
                body={popupData.body}
                backgroundColor={popupData.backgroundColor}
                textColor={popupData.textColor}
                onClose={handleClosePopup}
            />
        )}
        <div className="col-md-7 col-12">
          <div className="row">
            <div className="col-lg-12">
              <div>
                <div className="doctor-filter-info">
                  <div className="doctor-filter-inner">
                    <div>
                      <div className="doctors-found">
                        <p>
                          <div>
                            <span>{totalDoctor ? totalDoctor : 0} Dottori trovati</span>
                            {doctors && doctors.length > 0 && (
                                <>
                                  {Spe || Pid ? ': ' : ''}
                                  {Spe && `${Spe}`}
                                  {Spe && Pid && ', '}
                                  {Pid && `${Pid}`}
                                </>
                            )}
                          </div>
                        </p>
                      </div>
                      <div className="doctor-filter-availability d-none">
                        <p>Availability</p>
                        <div className="status-toggle status-tog">
                          <input type="checkbox" id="status_6" className="check"/>
                          <label htmlFor="status_6" className="checktoggle">
                            checkbox
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="doctor-filter-option">
                      <div className="doctor-filter-sort">
                        <p>Ordine alfabetico dalla:</p>
                        <div className="doctor-filter-select">
                          <Select
                              className="select w-100"
                              value={sortOption}
                              options={options}
                              onChange={handleSortChange}
                              placeholder="A alla Z"
                              isSearchable={false}
                          />
                        </div>
                      </div>
                      <div className="doctor-filter-sort d-none">
                        <p className="filter-today d-flex align-items-center">
                          <FiCalendar/> Today 10 Aug to 20 Aug
                        </p>
                      </div>
                      {/* <div className="doctor-filter-sort">
                    <ul className="nav">
                      <li>
                        <Link to="/patient/doctor-list" id="map-list">
                          <FiMapPin />
                        </Link>
                      </li>
                    </ul>
                  </div>*/}
                    </div>
                  </div>
                </div>
                <style>
                  {`
                  
                      .custom-prime-select{
                        border: 1px solid  #1C244B !important;
                        background: #1C244B;
                        color: lightgrey;
                        margin-top: 10px !important;
                        border-radius: 10px !important;
                        padding-right: 10px !important;
                        padding-left: 10px !important;
                        position: relative; /* Mantiene il contesto per il dropdown */
                        width: 100%;
                      }
                      
                      .custom-prime-select .p-dropdown-label {
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          width: 80%; /* o una larghezza specifica, es: 200px */
                          max-width: 300px;
                          color: white;
                      }
                      
                      .custom-prime-select.p-dropdown.p-component.p-inputwrapper{
                        width: 75% !important;
                        min-width: 75%;
                        max-width: 75% !important;
                      }
                      
                      .custom-dropdown .p-dropdown-item {
                          color: grey;
                          display: block !important;
                          flex-direction: column !important;
                          display: block !important;
                          width: 100% !important;
                      }

                      .custom-dropdown .p-dropdown-item.p-highlight {
                          background-color: #1C244B !important; /* Sfondo blu scuro per l'opzione selezionata */
                          color: white !important; /* Colore del testo nero per l'opzione selezionata */
                      }

                    .custom-dropdown {
                        position: absolute !important; /* Posiziona il dropdown in modo assoluto */
                        top: 100% !important; /* Posiziona il dropdown sotto la select */
                        left: 0 !important; /* Allinea il dropdown con la select */
                        z-index: 1000; /* Assicura che sia sopra altri elementi */
                        background-color: white !important; /* Sfondo dropdown blu scuro */
                        border-radius: 10px;
                        width: 80%;
                    }
                    
                    div.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
                      padding: 5px  10px!important;
                    }
                    
                    .p-dropdown-panel{
                      z-index: 999 !important;
                    }
                          
                    .day-slot ul li,
                    .time-slot ul li {
                      width: 16.6% !important;
                    } 
                    
                    .option-office{
                      font-size: 14px;
                    }
                    
                    .btn-option,
                    .btn-option:hover{
                      color: #1C244B;
                      background-color: #fff !important;
                      border-color: #1C244B !important;
                    }
                    
                    .button-more{
                      display: flex;
                      justify-content: center;
                      width: 100% !important;
                      margin-top: 15px;
                      margin-bottom: 35px;
                    }
                    
                    .doctor-widget-one .clinic-booking.book-appoint .btn-primary:hover{
                      color: #1C244B;
                      background-color: #fff;
                      border-color: #1C244B;
                    }
                    
                    .doctor-widget-one .clinic-booking.book-appoint .btn-primary{
                      color: #fff;
                      background-color: #1C244B;
                      border-color: #1C244B;
                    }
                    
                    .btn-primary-op:hover{
                      color: #0fC2EA !important;
                      background-color: #fff !important;
                      border-color: #0fC2EA !important;
                    }
                    
                     .btn-primary-op{
                      color: #fff !important;
                      background-color: #0fC2EA !important;
                      border-color: #0fC2EA !important;
                    }
                    
                    .p-dropdown-item-label{
                      font-size: 15px !important;
                      text-transform: capitalize !important;
                    }
                     
                     .clearfix{
                        overflow: hidden;
                        transition: max-height 0.5s ease; /
                    }
                    
                    .doc-info-cont{
                      width: 92.5%;
                    }
                    
                    .schedule-widget{
                      width: 102.5%;
                    }
                    
                    .card-body{
                      padding: 0.8rem 0.8rem;
                    }
                      
                    .font-size-option{
                      font-size: 9.3px !important;
                    }
                    
                    @media only screen and (max-width: 560px) {
                      
                      .booking-schedule.schedule-widget {
                          overflow-x: hidden; 
                          -webkit-overflow-scrolling: touch;
                      }
                      
                      .booking-schedule.schedule-widget > div{
                        width: 100% !important;
                      }
                      
                      .doctor-filter-inner{
                        flex-direction: column;
                      }
                      
                      .mobile-alert-option{
                        width: 100%;
                      }
                      
                      .doctor-filter-info{
                        display: flex;
                        justify-content: center;
                      }
                      
                      .custom-prime-select.p-dropdown.p-component.p-inputwrapper{
                        width: 95% !important;
                        min-width: 95% !important;
                      }
                      
                      .booking-schedule .day-slot ul{
                        text-align: center;
                        width: 100% !important;
                      }
                      
                      .flex-display ul{
                        display: block !important;
                      }
                      
                      .inline-display{
                        display: flex !important;
                      }
                      
                      .schedule-cont{
                        padding: 10px !important;
                      }
                      
                      .book-appoint{
                        width: 100% !important;
                      }
                      
                      .mobile-doctors{
                        text-align: left;
                      }
                      
                      .doctor-widget-one .doctor-img{
                        width: 120px !important;
                        height: 120px !important;
                      }
                      
                      .doc-info-cont{
                        width: 100% !important;
                      }
                      
                      .doctor-widget-one .clinic-details{
                        padding-bottom: 0px;
                      }
                      
                      .option-mobile-none{
                        width: 100%;
                      }
                      
                      .schedule-widget{
                        width: 100% !important;
                      }
                      
                      .button-more{
                        width: 100% !important;
                      }
                      
                      .font-size-option{
                        font-size: 12px !important;
                      }
                      
                      .page-link{
                        padding: 0.375rem 0.5rem;
                      }
                    }
                  `}
                </style>
                {doctors.length > 0 && (
                    <div className="row mt-5">
                      <div className="col-sm-12">
                        <div className="blog-pagination rev-page">
                          <nav>
                            <ul className="pagination justify-content-center">

                              {/* Bottone "Precedente" */}
                              <li className="page-item">
                                <button
                                    className="page-link page-prev"
                                    onClick={() => {
                                      setCurrentPage(prev => Math.max(prev - 1, 1));
                                      setTimeout(() => toTop(), 100);
                                      setMapLoaded(false);
                                    }}
                                    disabled={currentPage === 1}
                                >
                                  <i className="feather-chevrons-left me-1"/>
                                  Precedente
                                </button>
                              </li>

                              {(() => {
                                const totalPages = Math.ceil(totalDoctor / doctorsPerPage);
                                const pageNumbers = [];
                                const maxPagesToShow = 3;
                                const halfRange = Math.floor(maxPagesToShow / 2);
                                let startPage = Math.max(1, currentPage - halfRange);
                                let endPage = Math.min(totalPages, currentPage + halfRange);

                                if (startPage === 1) {
                                  endPage = Math.min(totalPages, maxPagesToShow);
                                }
                                if (endPage === totalPages) {
                                  startPage = Math.max(1, totalPages - maxPagesToShow + 1);
                                }

                                if (startPage > 1) {
                                  pageNumbers.push(
                                      <li key={1} className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => {
                                              setCurrentPage(1);
                                              setTimeout(() => toTop(), 100);
                                              setMapLoaded(false);
                                            }}
                                        >
                                          1
                                        </button>
                                      </li>
                                  );
                                  if (startPage > 2) {
                                    pageNumbers.push(
                                        <li key="dots-start" className="page-item disabled">
                                          <span className="page-link">...</span>
                                        </li>
                                    );
                                  }
                                }

                                for (let i = startPage; i <= endPage; i++) {
                                  pageNumbers.push(
                                      <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => {
                                              setCurrentPage(i);
                                              setTimeout(() => toTop(), 100);
                                              setMapLoaded(false);
                                            }}
                                        >
                                          {i}
                                        </button>
                                      </li>
                                  );
                                }

                                if (endPage < totalPages) {
                                  if (endPage < totalPages - 1) {
                                    pageNumbers.push(
                                        <li key="dots-end" className="page-item disabled">
                                          <span className="page-link">...</span>
                                        </li>
                                    );
                                  }
                                  pageNumbers.push(
                                      <li key={totalPages}
                                          className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => {
                                              setCurrentPage(totalPages);
                                              setTimeout(() => toTop(), 100);
                                              setMapLoaded(false);
                                            }}
                                        >
                                          {totalPages}
                                        </button>
                                      </li>
                                  );
                                }

                                return pageNumbers;
                              })()}

                              {/* Bottone "Successivo" */}
                              <li className="page-item">
                                <button
                                    className="page-link page-next"
                                    onClick={() => {
                                      setCurrentPage(prev => Math.min(prev + 1, Math.ceil(totalDoctor / doctorsPerPage)));
                                      setTimeout(() => toTop(), 100);
                                      setMapLoaded(false);
                                    }}
                                    disabled={currentPage === Math.ceil(totalDoctor / doctorsPerPage)}
                                >
                                  Successivo <i className="feather-chevrons-right ms-1"/>
                                </button>
                              </li>

                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                )}

                <div className="doctor-list">
                  {doctors && doctors.map((doctor, index) => (
                      <div key={doctor.practitioner.id} className="card doctor-card">
                        <div className="card-body">
                          <div className="doctor-widget-one">
                            <div className="doc-info-left row mb-2">
                              <div className="col-12">
                                <div className="doctor-img mb-2 mb-md-0">
                                  <Link
                                      to={`/profilo-dottore/${encodeURIComponent((doctor?.practitioner?.lastName || '').replace(/\s+/g, '-'))}-${encodeURIComponent((doctor?.practitioner?.firstName || '').replace(/\s+/g, '-'))}/${encodeURIComponent((doctor?.specialization?.description || '').replace(/\s+/g, '-'))}/${doctor?.practitioner?.id || ''}/${doctor?.practitionerSpecializationId || ''}/${doctor?.specialization?.id || ''}`}>
                                    {doctor.practitioner?.practitionerInfo?.practitionerImages?.length > 0 && doctor.practitioner.practitionerInfo.practitionerImages[0]?.url !== null ? (
                                        <img
                                            src={doctor.practitioner.practitionerInfo.practitionerImages[0].url}
                                            className="img-fluid rounded"
                                            alt=""
                                            style={{maxWidth: '100%', height: 'auto'}}
                                        />
                                    ) : (
                                        <img
                                            src="/assets/img/doctor-placeholder.png"
                                            className="img-fluid rounded"
                                            alt=""
                                            style={{maxWidth: '100%', height: 'auto'}}
                                        />
                                    )}
                                  </Link>
                                </div>
                                <div className="doc-info-cont">
                                  <h4 className="ms-0 doc-name mt-3">
                                    <Link
                                        to={`/profilo-dottore/${encodeURIComponent((doctor?.practitioner?.lastName || '').replace(/\s+/g, '-'))}-${encodeURIComponent((doctor?.practitioner?.firstName || '').replace(/\s+/g, '-'))}/${encodeURIComponent((doctor?.specialization?.description || '').replace(/\s+/g, '-'))}/${doctor?.practitioner?.id || ''}/${doctor?.practitionerSpecializationId || ''}/${doctor?.specialization?.id || ''}`}>
                                      Dr. {doctor.practitioner.lastName} {doctor.practitioner.firstName}
                                    </Link>
                                    <i className="fas fa-circle-check"/>
                                  </h4>
                                  <p className="doc-speciality">{doctor.specialization.description}</p>
                                  <div className="clinic-details">
                                    <p className="doc-location ms-0">
                                      {doctor.practitioner?.addressMunicipality?.municipality || doctor.practitioner?.addressMunicipality?.province ? (
                                          <div>
                                            <FiMapPin className="me-1"/>
                                            {doctor.practitioner?.addressMunicipality?.municipality || ''}, {doctor.practitioner?.addressMunicipality?.province || ''}
                                          </div>
                                      ) : null}
                                    </p>
                                  </div>
                                  <div className="clinic-details">
                                    {doctor && doctor.selectedOffice ? (
                                        <div>
                                          <p className="option-office">
                                            <strong>Ambulatorio:</strong> {doctor.selectedOffice.data?.address || ''}
                                          </p>
                                          <p className="option-office">
                                            {`${doctor.selectedOffice.data?.name || ''}${doctor.selectedOffice.data?.name && doctor.selectedOffice.data?.postalCode ? ', ' : ''}${doctor.selectedOffice.data?.postalCode || ''}${doctor.selectedOffice.data?.postalCode && doctor.selectedOffice.data?.province?.description ? ', ' : ''}${doctor.selectedOffice.data?.province?.description || ''}`}
                                          </p>
                                        </div>
                                    ) : (
                                        <p>Nessuna informazione sull'ambulatorio.</p>
                                    )}
                                  </div>
                                  <div className="clinic-booking book-appoint mt-1 mt-md-3">
                                    <Link className="btn btn-primary-op"
                                          to={`/profilo-dottore/${encodeURIComponent((doctor?.practitioner?.lastName || '').replace(/\s+/g, '-'))}-${encodeURIComponent((doctor?.practitioner?.firstName || '').replace(/\s+/g, '-'))}/${encodeURIComponent((doctor?.specialization?.description || '').replace(/\s+/g, '-'))}/${doctor?.practitioner?.id || ''}/${doctor?.practitionerSpecializationId || ''}/${doctor?.specialization?.id || ''}`}>
                                      Maggiori <br/>informazioni
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="booking-schedule schedule-widget ps-0">
                              <div className="schedule-cont">
                                <div className="row">
                                  <div className="vertical-layout option-border">
                                    <div className="booking-schedule schedule-widget ps-0">
                                      <div className="schedule-header">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="day-slot vertical-layout">
                                              {doctor.availability && doctor.availability.length > 0 ? (
                                                  <div>
                                                    <div className="row mobile-doctors">
                                                      <div className="col-md-10 col-8">
                                                        Seleziona e prenota il tuo appuntamento:
                                                      </div>
                                                      <div className="col-3 col-md-2 ms-3 ms-md-0 buttons-container">
                                                        <button
                                                            className="button-option-availability"
                                                            onClick={() => goToPreviousWeek(index)}>
                                                          {"<"}
                                                        </button>
                                                        <button
                                                            className="button-option-availability"
                                                            onClick={() => goToNextWeek(index)}>
                                                          {">"}
                                                        </button>
                                                      </div>
                                                    </div>
                                                    <ul className="inline-display">
                                                      {doctor.availability.map((item, index) => (
                                                          <li key={index}>
                                                            <span className="font-size-option">
                                                              {['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'][item.dayOfWeek - 1]}
                                                            </span>
                                                            <span className="slot-date">
                                                              {new Date(item.day).toLocaleDateString('it-IT', {
                                                                day: 'numeric',
                                                                month: 'short'
                                                              })}
                                                            </span>
                                                          </li>
                                                      ))}
                                                    </ul>
                                                  </div>
                                              ) : (
                                                  <div style={{textAlign: "center"}}
                                                       className="mt-4 option-mobile-none">
                                                    {doctor.office && doctor.office.length > 0 && doctor.appointmentTypes && doctor.appointmentTypes.length > 0 ? (
                                                        <div>
                                                          <div>
                                                            <h5>Seleziona ambulatorio e tipologia di appuntamento per
                                                              visualizzare le disponibilità:</h5>
                                                          </div>
                                                          <div>
                                                            <div key={doctor.practitionerSpecializationId}>
                                                              <Dropdown
                                                                  value={doctor.selectedOffice ? doctor.selectedOffice.data.id : null}
                                                                  onChange={(e) => handleOfficeChange(doctor.practitionerSpecializationId, e.value)}
                                                                  options={doctor.office ? doctor.office.map(office => ({
                                                                    label: office.data.name,
                                                                    value: office.data.id
                                                                  })) : []}
                                                                  optionLabel="label"
                                                                  optionValue="value"
                                                                  placeholder="Seleziona un ambulatorio"
                                                                  className="custom-prime-select flex-display"
                                                                  panelClassName="custom-dropdown"
                                                                  appendTo="self"
                                                              />

                                                              <Dropdown
                                                                  value={doctor.selectedAppointmentType ? doctor.selectedAppointmentType.scheduleAppointmentTypeId : null}
                                                                  onChange={(e) => handleAppointmentTypeChange(doctor.practitionerSpecializationId, e.value)}
                                                                  options={doctor.appointmentTypes ? doctor.appointmentTypes.map(type => ({
                                                                    label: type.description,
                                                                    value: type.scheduleAppointmentTypeId
                                                                  })) : []}
                                                                  optionLabel="label"
                                                                  optionValue="value"
                                                                  placeholder="Seleziona tipo di appuntamento"
                                                                  className="custom-prime-select flex-display"
                                                                  panelClassName="custom-dropdown"
                                                                  appendTo="self"
                                                              />

                                                              <button className="btn btn btn-option mt-3"
                                                                      onClick={updateAvailability}>
                                                                Aggiorna Disponibilità
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                          <p className="mt-5">Nessuna disponibilità.</p>
                                                          <p className="mt-2">Chiedi al tuo dottore di attivare il
                                                            servizio <br/>di “Segreteria Medica 3SMB".</p>
                                                        </div>
                                                    )}
                                                  </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="schedule-cont">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="time-slot">
                                              <ul
                                                  className="clearfix mx-2 mx-md-0"
                                                  ref={(el) => (listRefs.current[doctor.practitioner.id] = el)}
                                                  style={{
                                                    maxHeight: expandedDoctor === doctor.practitioner.id ? 'none' : '220px',
                                                    overflow: 'hidden',
                                                    transition: 'max-height 0.3s ease'
                                                  }}>
                                                {doctor.availability && Array.isArray(doctor.availability) && doctor.availability.map((item, index) => {
                                                  const itemDate = new Date(item.day);
                                                  const todayDate = new Date();

                                                  itemDate.setHours(0, 0, 0, 0);
                                                  todayDate.setHours(0, 0, 0, 0);

                                                  return (
                                                      <li key={index} style={{marginBottom: '10px'}}>
                                                        {item.slotsByOffice && itemDate >= todayDate && item.slotsByOffice.length > 0 ? (
                                                            item.slotsByOffice.flatMap((office, officeIndex) => {
                                                              let hasAvailableSlots = false;
                                                              const slots = Array.isArray(office.blocks) ? office.blocks.flatMap((block, blockIndex) => {
                                                                if (block && block.scheduleAvailability && block.scheduleAvailability.scheduleAppointmentCategory && block.scheduleAvailability.scheduleAppointmentCategory.patientVisible) {
                                                                  let subSlots = [];

                                                                  if (block.freeTimes) {
                                                                    block.freeTimes.forEach(freeTime => {
                                                                      const generatedSlots = generateSubSlots(
                                                                          freeTime.startTime,
                                                                          freeTime.endTime,
                                                                          itemDate,
                                                                          doctor.selectedAppointmentType
                                                                      );
                                                                      subSlots = subSlots.concat(generatedSlots); // Accumula i sub-slot generati
                                                                    });

                                                                    if (subSlots.length > 0) {
                                                                      hasAvailableSlots = true;
                                                                    }

                                                                    return subSlots.map((slot, slotIndex) => (
                                                                        <div className="slot-option"
                                                                             key={`${officeIndex}-${blockIndex}-${slotIndex}`}>
                                                                          <a href={`/checkout/${slot.start}/${slot.end}/${item.day}/${doctor.practitioner.id}/${doctor.practitionerSpecializationId}/${doctor.selectedOffice?.data?.id}/${doctor.selectedAppointmentType?.scheduleAppointmentTypeId}`}>
                                                                            <span>{slot.start}</span>
                                                                          </a>
                                                                        </div>
                                                                    ));
                                                                  }
                                                                } else if (block && !block.scheduleAvailability) {
                                                                  hasAvailableSlots = true;
                                                                }
                                                                return [];
                                                              }) : [];

                                                              return (
                                                                  <React.Fragment key={officeIndex}>
                                                                    {slots}
                                                                    {!hasAvailableSlots &&
                                                                        <p className="no-availability">_</p>}
                                                                  </React.Fragment>
                                                              );
                                                            })
                                                        ) : (
                                                            <p className="no-availability">_</p>
                                                        )}
                                                      </li>
                                                  );
                                                })}
                                              </ul>
                                              <div className="button-more">
                                                <button
                                                    onClick={() => toggleContent(doctor.practitioner.id)}
                                                    id={`button-show-${doctor.practitioner.id}`}
                                                    ref={(el) => (buttonRefs.current[doctor.practitioner.id] = el)}
                                                    style={{
                                                      border: 'none',
                                                      padding: '5px 10px',
                                                      borderRadius: '5px',
                                                      cursor: 'pointer',
                                                      display: 'none',
                                                      zIndex: '999'
                                                    }}>
                                                  {expandedDoctor === doctor.practitioner.id ? (
                                                      <>
                                                        Mostra Meno <i className="fas fa-chevron-up"
                                                                       style={{marginLeft: '10px'}}></i>
                                                      </>
                                                  ) : (
                                                      <>
                                                        Mostra Tutti <i className="fas fa-chevron-down"
                                                                        style={{marginLeft: '10px'}}></i>
                                                      </>
                                                  )}
                                                </button>
                                              </div>
                                              <div className="mobile-alert-option">
                                                {doctor.alerts && doctor.alerts.map((alert, index) => (
                                                    <div
                                                        key={index}
                                                        id={`alert-${alert.alertId}`}
                                                        className="alert alert-success mt-2"
                                                        role="alert"
                                                        style={{
                                                          backgroundColor: alert.backgroundColor,
                                                          color: alert.textColor,
                                                          borderColor: alert.borderColor,
                                                          padding: '10px 10px 0px 10px'
                                                        }}
                                                    >
                                                      <h6>{alert.title}</h6>
                                                      <p>{alert.body}</p>
                                                    </div>
                                                ))}
                                              </div>
                                              <div className="d-none">
                                                {doctor.selectedAppointmentType && doctor.selectedAppointmentType.description ? (
                                                    <p style={{
                                                      position: 'absolute',
                                                      bottom: '15px',
                                                      marginTop: '15px'
                                                    }}>Tipologia di
                                                      visita: {doctor.selectedAppointmentType.description}</p>
                                                ) : (
                                                    <p></p>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  ))}
                </div>

                {doctors.length > 0 && (
                    <div className="row mt-5">
                      <div className="col-sm-12">
                        <div className="blog-pagination rev-page">
                          <nav>
                            <ul className="pagination justify-content-center">

                              {/* Bottone "Precedente" */}
                              <li className="page-item">
                                <button
                                    className="page-link page-prev"
                                    onClick={() => {
                                      setCurrentPage(prev => Math.max(prev - 1, 1));
                                      setTimeout(() => toTop(), 100);
                                      setMapLoaded(false);
                                    }}
                                    disabled={currentPage === 1}
                                >
                                  <i className="feather-chevrons-left me-1"/>
                                  Precedente
                                </button>
                              </li>

                              {(() => {
                                const totalPages = Math.ceil(totalDoctor / doctorsPerPage);
                                const pageNumbers = [];
                                const maxPagesToShow = 3;
                                const halfRange = Math.floor(maxPagesToShow / 2);
                                let startPage = Math.max(1, currentPage - halfRange);
                                let endPage = Math.min(totalPages, currentPage + halfRange);

                                if (startPage === 1) {
                                  endPage = Math.min(totalPages, maxPagesToShow);
                                }
                                if (endPage === totalPages) {
                                  startPage = Math.max(1, totalPages - maxPagesToShow + 1);
                                }

                                if (startPage > 1) {
                                  pageNumbers.push(
                                      <li key={1} className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => {
                                              setCurrentPage(1);
                                              setTimeout(() => toTop(), 100);
                                              setMapLoaded(false);
                                            }}
                                        >
                                          1
                                        </button>
                                      </li>
                                  );
                                  if (startPage > 2) {
                                    pageNumbers.push(
                                        <li key="dots-start" className="page-item disabled">
                                          <span className="page-link">...</span>
                                        </li>
                                    );
                                  }
                                }

                                for (let i = startPage; i <= endPage; i++) {
                                  pageNumbers.push(
                                      <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => {
                                              setCurrentPage(i);
                                              setTimeout(() => toTop(), 100);
                                              setMapLoaded(false);
                                            }}
                                        >
                                          {i}
                                        </button>
                                      </li>
                                  );
                                }

                                if (endPage < totalPages) {
                                  if (endPage < totalPages - 1) {
                                    pageNumbers.push(
                                        <li key="dots-end" className="page-item disabled">
                                          <span className="page-link">...</span>
                                        </li>
                                    );
                                  }
                                  pageNumbers.push(
                                      <li key={totalPages}
                                          className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => {
                                              setCurrentPage(totalPages);
                                              setTimeout(() => toTop(), 100);
                                              setMapLoaded(false);
                                            }}
                                        >
                                          {totalPages}
                                        </button>
                                      </li>
                                  );
                                }

                                return pageNumbers;
                              })()}

                              {/* Bottone "Successivo" */}
                              <li className="page-item">
                                <button
                                    className="page-link page-next"
                                    onClick={() => {
                                      setCurrentPage(prev => Math.min(prev + 1, Math.ceil(totalDoctor / doctorsPerPage)));
                                      setTimeout(() => toTop(), 100);
                                      setMapLoaded(false);
                                    }}
                                    disabled={currentPage === Math.ceil(totalDoctor / doctorsPerPage)}
                                >
                                  Successivo <i className="feather-chevrons-right ms-1"/>
                                </button>
                              </li>

                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                )}

                <style>
                  {`
                  .slot-date{
                    font-size: 11px !important;
                  }
                  
                  .slot-year{
                    font-size: 11px !important;
                  }
                  
                  .book-appoint{
                    width: 100%;
                  }
                  
                  .time-slot ul li .timing{
                    padding: 4px 2px !important;
                    font-size: 12px !important;
                  }
                  
                  .fa-chevron-left{
                    margin-right: 10px !important;
                  }
           
                  .buttons-container {
                      display: flex;
                      justify-content: flex-end;
                  }                    
                  
                  .button-option-availability{
                      background-color: #9FE6F6;
                      color: #0B9CBD;
                      border: 0px;
                      border-radius: 100px;
                      padding: 0px 8px 3px 8px;
                      font-size: 25px;
                      margin-right: 5px;
                      margin-left: 5px;
                      margin-top: -10px;
                  }           
                  
                  .schedule-widget .schedule-header{
                      border-bottom: 0px !important;
                      padding: 10px 0px;
                  }    
                  
                  .schedule-cont{
                      padding: 5px 0px;
                  }     
                  
                  .day-slot ul li span.slot-date{
                      font-size: 11px;
                  }
                  
                  .no-availability{
                      font-size: 11px;
                  }
                  
                  .day-slot ul li, .time-slot ul li {
                      width: 16.66% !important;
                      margin-top: 10px;
                  }                                                
                  
                  .slot-option {
                        margin-bottom: 0px;
                        background-color: #9FE6F6;
                        padding: 1px 8px;
                        border-radius: 4px;
                        display: inline-block;
                        margin-right: 5px;
                        font-size: 13px;
                        transition: background-color 0.3s ease;
                  }
                  
                  .slot-option:hover {
                    background-color: #7FC9D8; /* Colore di sfondo diverso quando l'elemento è in hover */
                    cursor: pointer; /* Cambia il cursore per indicare che l'elemento è interattivo */
                  }
                  
                  .doctor-content{
                    min-height: 80vh;
                  }
    
              `}
                </style>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5 d-none d-md-block">
          <div className="container sticky-option">
            <div className="contact-map-iframe d-flex align-content-center">
              {mapLoaded && <WrappedMapContainer initialCenter={initialCenter}/>}
            </div>
          </div>
        </div>
        <style>
          {`
            .sticky-option{
                position: sticky; 
                top: 110px;
            }
            
            .contact-map-iframe{
               border-radius: 15px !important;
               overflow: hidden;
            }

            .loader {
              width: 150px;
              position: fixed;
              top: 50%;
              left: 48%;
              transform: translate(-50%, -50%); /* Per centrare l'immagine */
              animation: pulse 1s infinite; /* Animazione di pulsazione */
              z-index: 99999;
            }

            @media only screen and (max-width: 600px) {
                .loader {
                    left: 38%;
                }
                
                .day-slot ul{
                    padding: 2% 5%;
                }
                
                .time-slot ul {
                    padding: 0% 0%;
                }
            }

            @keyframes pulse {
              0% {
                transform: scale(1); /* Dimensione iniziale */
              }
              50% {
                transform: scale(1.1); /* Ingrandimento */
              }
              100% {
                transform: scale(1); /* Torna alla dimensione originale */
              }
            }

            .overlay {
              position: fixed; 
              left: 0; 
              width: 100%;
              height: 110%;
              top: -10%;
              background-color: rgba(0, 0, 0, 0.5); /* Colore semi-trasparente */
              z-index: 9999; /* Assicurati che l'overlay sia sotto il loader ma sopra il contenuto */
            }
          `}
        </style>
        <div>
          {loading ? <img src="/assets/img/logo-Sfondo-trasparente-Segreteria-medica-3SMB-1.png" alt="Logo"
                          className="loader"/> : ''}
          {loading && <div className="overlay"></div>}
        </div>
      </div>
  );
};

export default Doctors;